import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";

class TaskCreateDrawer extends React.Component {
  state = {
    dueDate: moment().toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    stores: [],
    store: "",
    activity: "",
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.store) != JSON.stringify(prevProps.store)) {
      if (!this.props.storeTask) {
        this.getAllStaff();
      }

      this.setState({
        store: this.props.store?._id,
      });
    }

    if (this.props.prefill != prevProps.prefill && this.props.prefill) {
      console.log(this.props.prefill);

      this.setState({
        ...this.props.prefill,
      });
    }

    if (
      this.props.open &&
      this.props.store &&
      this.props.store?._id != this.state.store
    ) {
      this.setState({
        store: this.props.store?._id,
      });
    }

    if (this.props.storeTask != prevProps.storeTask && this.props.storeTask) {
      this.getAllUsers();
    }

    if (
      this.props.allowAssignStore != prevProps.allowAssignStore &&
      this.props.allowAssignStore
    ) {
      this.getAllStores();
    }

    if (prevProps.customer != this.props.customer && this.props.customer) {
      this.setState({
        relatedToType: "Member",
        relatedCustomer: this.props.customer,
      });
    }

    if (prevProps.lead != this.props.lead && this.props.lead) {
      this.setState({
        relatedToType: "Lead",
        relatedCustomer: this.props.lead,
      });
    }

    if (prevProps.affiliate != this.props.affiliate && this.props.affiliate) {
      this.setState({
        relatedToType: "Affiliate",
        relatedCustomer: this.props.affiliate,
      });
    }
  }

  componentDidMount() {
    if (this.props.store) {
      this.setState({
        store: this.props.store?._id,
      });
    }

    if (this.props.store && !this.props.storeTask) {
      this.getAllStaff();
    } else if (this.props.storeTask) {
      this.getAllUsers();
    }

    if (this.props.storeTask && this.props.allowAssignStore) {
      this.getAllStores();
    }

    if (this.props.customer) {
      this.setState({
        relatedToType: "Member",
        relatedCustomer: this.props.customer,
      });
    }

    if (this.props.lead) {
      this.setState({
        relatedToType: "Lead",
        relatedCustomer: this.props.lead,
      });
    }

    if (this.props.affiliate) {
      this.setState({
        relatedToType: "Affiliate",
        relatedCustomer: this.props.affiliate,
      });
    }
  }

  toggleModal() {
    this.setState({
      dueDate: moment().toDate(),
      subject: "",
      description: "",
      relatedToType: "",
      type: "",
      priority: "Normal",
      status: "Not Started",
      assignedTo: null,
      timeToContact: "",
      customers: [],
      store: "",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  createGlobalTask() {
    this.setState({ submitting: true });

    let dueDate = moment(this.state.dueDate?.toISOString())
      .minutes(0)
      .hours(0)
      .seconds(0)
      .millisecond(0);

    APIV2.createGlobalTask(
      this.state.store ? this.state.store : "",
      this.state.subject,
      this.state.type,
      this.state.description,
      this.state.priority,
      this.state.relatedCustomer && this.state.relatedToType == "Member"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.relatedCustomer && this.state.relatedToType == "Lead"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.relatedCustomer && this.state.relatedToType == "Affiliate"
        ? this.state.relatedCustomer?._id
        : null,
      null,
      this.state.status,
      dueDate.toDate().toISOString(),
      this.state.assignedTo,
      this.state.timeToContact,
      this.props.storeTask ? true : false
    )
      .then(
        (data) => {
          const task = data.data.task;

          this.toggleModal();

          PubSub.publish(Event.TASK.CREATED, task);

          this.setState({
            dueDate: moment().toDate(),
            subject: "",
            description: "",
            relatedToType: "",
            type: "",
            priority: "Normal",
            status: "Not Started",
            assignedTo: null,
            timeToContact: "",
            customers: [],
            store: "",
          });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create task - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  createFranDevLeadTask() {
    this.setState({ submitting: true });

    let leadIDs = this.props.frandevLead
      ? [this.props.frandevLead?._id]
      : this.props.frandevLeads?.length
      ? this.props.frandevLeads
      : [];

    let dueDate = moment(this.state.dueDate?.toISOString())
      .minutes(0)
      .hours(0)
      .seconds(0)
      .millisecond(0);

    let promises = [];

    leadIDs.forEach((leadID) => {
      promises.push(
        APIV2.createGlobalTask(
          null,
          this.state.subject,
          this.state.type,
          this.state.description,
          this.state.priority,
          null,
          null,
          null,
          leadID,
          this.state.status,
          dueDate.toDate().toISOString(),
          this.state.assignedTo,
          null,
          true,
          this.state.activity ? this.state.activity : ""
        ).then((data) => {
          const task = data.data.task;

          this.toggleModal();

          PubSub.publish(Event.TASK.CREATED, task);
        })
      );
    });

    Promise.all(promises)
      .then(
        (data) => {
          this.toggleModal();

          this.setState({
            dueDate: moment().toDate(),
            subject: "",
            description: "",
            relatedToType: "",
            type: "",
            priority: "Normal",
            status: "Not Started",
            assignedTo: null,
            timeToContact: "",
            customers: [],
            store: "",
          });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create task - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  createRegularTask() {
    this.setState({ submitting: true });

    let dueDate = moment(this.state.dueDate?.toISOString())
      .minutes(0)
      .hours(0)
      .seconds(0)
      .millisecond(0);

    APIV2.createTask(
      this.props?.store?._id,
      this.state.subject,
      this.state.type,
      this.state.description,
      this.state.priority,
      this.state.relatedCustomer && this.state.relatedToType == "Member"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.relatedCustomer && this.state.relatedToType == "Lead"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.relatedCustomer && this.state.relatedToType == "Affiliate"
        ? this.state.relatedCustomer?._id
        : null,
      this.state.status,
      dueDate.toDate().toISOString(),
      this.state.assignedTo,
      this.state.timeToContact,
      this.props.storeTask ? true : false
    )
      .then(
        (data) => {
          const task = data.data.task;

          this.toggleModal();

          PubSub.publish(Event.TASK.CREATED, task);

          this.setState({
            dueDate: moment().toDate(),
            subject: "",
            description: "",
            relatedToType: "",
            type: "",
            priority: "Normal",
            status: "Not Started",
            assignedTo: null,
            timeToContact: "",
            customers: [],
          });
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create task - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  create() {
    if (this.props.frandevLeads?.length) {
      this.createFranDevLeadTask();
    } else if (this.props.storeTask) {
      this.createGlobalTask();
    } else {
      this.createRegularTask();
    }
  }

  getAllStores() {
    APIV2.getStores().then((data) => {
      this.setState({ stores: data.data.stores });
    });
  }

  getAllStaff() {
    if (!this.props.store) {
      return;
    }

    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(this?.props?.store?._id, 1, 10000, {
      position: { $nin: ["Terminated", "Applicant"] },
    })
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              group: s?.position,
              name: s.name,
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  getAllUsers() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getAllUsers()
      .then(
        (data) => {
          let coachOptions = data.data.users?.map((s) => {
            let group = "Other Users";

            if (s?.productionPermissions?.length) {
              group = "Production Team";
            } else if (s?.globalPermissions?.length) {
              group = "Franchise Team";
            } else if (
              s?.email?.includes("@projectleannation.com") &&
              !s?.globalPermissions?.length &&
              (s?.email?.indexOf("pln") == 0 ||
                s?.email?.includes("info@") ||
                s?.email?.includes("clt@") ||
                s?.email?.includes("mtp@"))
            ) {
              group = "Store Admin Accounts";
            }

            return {
              group: group,
              name: s.name,
              value: s._id,
              label: (
                <>
                  <p className="m-0" style={{ lineHeight: 1.2 }}>
                    {s.name}
                  </p>
                  <p className="m-0 small">{s.email}</p>
                </>
              ),
            };
          });

          this.setState({
            coaches: data.data.users,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch users.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    let promise = null;

    if (this.props.location.pathname.indexOf("/storefront") == 0) {
      if (this.state.relatedToType == "Member") {
        promise = APIV2.simpleSearchCustomersForStore(
          this.props.match?.params?.storeID,
          this.state.search
        );
      } else if (this.state.relatedToType == "Lead") {
        promise = APIV2.simpleSearchLeadsForStore(
          this.props.match?.params?.storeID,
          this.state.search
        );
      } else if (this.state.relatedToType == "Affiliate") {
        promise = APIV2.simpleSearchAffiliatesForStore(
          this.props.match?.params?.storeID,
          this.state.search
        );
      }

      promise
        .then(
          (data) => {
            if (this.state.relatedToType == "Member") {
              this.setState({
                customers: data.data.customers,
              });
            } else if (this.state.relatedToType == "Lead") {
              this.setState({
                customers: data.data.leads,
              });
            } else if (this.state.relatedToType == "Affiliate") {
              this.setState({
                customers: data.data.affiliates,
              });
            }
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            searchLoading: false,
          });
        });
    } else {
      if (this.state.relatedToType == "Member") {
        promise = APIV2.simpleSearchCustomers(this.state.search);
      } else if (this.state.relatedToType == "Lead") {
        promise = APIV2.simpleSearchLeads(this.state.search);
      } else if (this.state.relatedToType == "Affiliates") {
        promise = APIV2.simpleSearchAffiliates(this.state.search);
      }

      promise
        .then(
          (data) => {
            if (this.state.relatedToType == "Member") {
              this.setState({
                customers: data.data.customers,
              });
            } else if (this.state.relatedToType == "Lead") {
              this.setState({
                customers: data.data.leads,
              });
            } else if (this.state.relatedToType == "Affiliate") {
              this.setState({
                customers: data.data.affiliates,
              });
            }
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            searchLoading: false,
          });
        });
    }
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    this.setState({ modified: true });

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              New Task
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.dueDate ||
                  !this.state.subject ||
                  !this.state.type
                }
                color="primary"
                onClick={this.create.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  "Add"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <h4 className="mb-3">Task Information</h4>
            {this.props.storeTask && this.props.allowAssignStore && (
              <>
                <FormGroup>
                  <h5>Store (Optional)</h5>
                  <SelectPicker
                    value={this.state.store}
                    data={this.state.stores?.map((store) => {
                      return {
                        value: store?._id,
                        label: store?.name,
                      };
                    })}
                    onChange={(v) => {
                      this.setState({
                        store: v,
                      });
                    }}
                    cleanable
                    placeholder="Select A Store"
                    placement="autoVertical"
                    block
                    size="sm"
                  ></SelectPicker>
                </FormGroup>
              </>
            )}
            <FormGroup>
              <h5>
                Subject
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="subject"
                placeholder="Subject"
                value={this.state.subject}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <Input
                bsSize="sm"
                type="textarea"
                name="description"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <Row>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>
                    Type
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="type"
                    placeholder="Type"
                    value={this.state.type}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="" disabled>
                      Select A Type
                    </option>
                    {this.props.storeTask ? (
                      <>
                        <option
                          value="Unit Operations"
                          disabled={
                            this.props.allowedTypes?.length &&
                            !this.props.allowedTypes?.includes(
                              "Unit Operations"
                            )
                          }
                        >
                          Unit Operations
                        </option>
                        <option
                          value="Fran-Dev"
                          disabled={
                            this.props.allowedTypes?.length &&
                            !this.props.allowedTypes?.includes("Fran-Dev")
                          }
                        >
                          Fran-Dev
                        </option>
                        <option
                          value="Fran-Support"
                          disabled={
                            this.props.allowedTypes?.length &&
                            !this.props.allowedTypes?.includes("Fran-Support")
                          }
                        >
                          Fran-Support
                        </option>
                        <option
                          value="Fran-Finance"
                          disabled={
                            this.props.allowedTypes?.length &&
                            !this.props.allowedTypes?.includes("Fran-Finance")
                          }
                        >
                          Fran-Finance
                        </option>

                        <option
                          value="Franchise Partner"
                          disabled={
                            this.props.allowedTypes?.length &&
                            !this.props.allowedTypes?.includes(
                              "Franchise Partner"
                            )
                          }
                        >
                          Franchise Partner
                        </option>

                        <option
                          value="Technology"
                          disabled={
                            this.props.allowedTypes?.length &&
                            !this.props.allowedTypes?.includes("Technology")
                          }
                        >
                          Technology
                        </option>
                        <option
                          value="Marketing"
                          disabled={
                            this.props.allowedTypes?.length &&
                            !this.props.allowedTypes?.includes("Marketing")
                          }
                        >
                          Marketing
                        </option>
                        <option
                          value="Production"
                          disabled={
                            this.props.allowedTypes?.length &&
                            !this.props.allowedTypes?.includes("Production")
                          }
                        >
                          Production
                        </option>
                      </>
                    ) : null}
                    {!this.props.storeTask ? (
                      <>
                        <option value="Lead Engagement">Lead Engagement</option>
                        <option value="Member Engagement">
                          Member Engagement
                        </option>
                        <option value="Affiliate Engagement">
                          Affiliate Engagement
                        </option>
                        <option value="Billing">Billing</option>
                        <option value="Fulfillment">Fulfillment</option>
                        <option value="Admin">Admin</option>
                      </>
                    ) : null}
                  </Input>
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Priority</h5>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="priority"
                    placeholder="Priority"
                    value={this.state.priority}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="Normal">Normal</option>
                    <option value="High">High</option>
                  </Input>
                </FormGroup>
              </Col>
              {this.props.storeTask ? (
                <Col xs="12" md="6">
                  <FormGroup>
                    <h5>Activity</h5>
                    <Input
                      bsSize="sm"
                      type="select"
                      name="activity"
                      placeholder="Activity"
                      value={this.state.activity}
                      onChange={this.handleInputChange.bind(this)}
                    >
                      <option value="">None</option>
                      <option value="Call">Call</option>
                      <option value="Email">Email</option>
                      <option value="Text / Direct Message">
                        Text / Direct Message
                      </option>
                      <option value="Meeting">Meeting</option>
                      <option value="Focus Call">Focus Call</option>
                      <option value="Discovery Call">Discovery Call</option>
                    </Input>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
            {!this.props.storeTask && (
              <>
                <hr className="my-3"></hr>
                <h4 className="mb-3">Related To</h4>
                <FormGroup>
                  <h5>Related To</h5>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="relatedToType"
                    placeholder="Related To"
                    value={this.state.relatedToType}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="" disabled>
                      Select An Option
                    </option>
                    <option value="Member">Member</option>
                    <option value="Lead">Lead</option>
                    <option value="Affiliate">Affiliate</option>
                    <option value="Other">Other</option>
                  </Input>
                </FormGroup>
                {this.state.relatedToType == "Member" ||
                this.state.relatedToType == "Lead" ||
                this.state.relatedToType == "Affiliate" ? (
                  <>
                    <FormGroup>
                      <h5>{this.state.relatedToType}</h5>
                      {this.state.relatedCustomer ? (
                        <>
                          <div className="form-control form-control-sm text-dark">
                            {this.state.relatedCustomer?.name
                              ? this.state.relatedCustomer?.name
                              : this.state.relatedCustomer?.email}
                          </div>
                          <div className="text-right mt-2">
                            <Button
                              onClick={() => {
                                this.setState({
                                  relatedCustomer: null,
                                  search: "",
                                });
                              }}
                              size="sm"
                              color="danger"
                              outline
                            >
                              Remove
                            </Button>
                          </div>
                        </>
                      ) : (
                        <Whisper
                          placement="autoVerticalStart"
                          trigger="focus"
                          enterable
                          speaker={
                            <Popover
                              style={{
                                width: "calc(100% - 20px)",
                                maxWidth: 450,
                              }}
                              className="p-0"
                            >
                              <div className="border-bottom p-2">
                                <h5 className="mb-1">
                                  {this.state.relatedToType}s
                                </h5>
                                <div className="mb-1">
                                  {!this.state.search ? (
                                    <>
                                      Search for a{" "}
                                      {this.state.relatedToType.toLowerCase()}.
                                    </>
                                  ) : null}
                                  {this.state.search &&
                                  this.state.searchLoading ? (
                                    <>Loading results...</>
                                  ) : null}
                                  {this.state.search &&
                                  !this.state.searchLoading &&
                                  !this.state.customers?.length ? (
                                    <>
                                      No{" "}
                                      {this.state.relatedToType.toLowerCase()}s
                                      matching your search.
                                    </>
                                  ) : null}
                                  {this.state.search &&
                                  this.state.customers?.length &&
                                  !this.state.searchLoading ? (
                                    <>{this.state.customers?.length} results</>
                                  ) : null}
                                </div>
                              </div>
                              <div
                                className=""
                                style={{
                                  maxHeight: "400px",
                                  overflowY: "scroll",
                                }}
                              >
                                {!this.state.searchLoading &&
                                  this.state.customers?.map((c, i) => (
                                    <>
                                      <div
                                        className="cursor-pointer bg-superlight--hover px-2 py-1"
                                        key={c?._id}
                                        onClick={() => {
                                          this.setState({
                                            relatedCustomer: c,
                                          });
                                        }}
                                      >
                                        <h4 className="m-0">
                                          {c?.name ? c.name : c.email}
                                        </h4>
                                        <p
                                          className="m-0"
                                          style={{ lineHeight: 1.2 }}
                                        >
                                          {this.state.relatedToType ==
                                          "Member" ? (
                                            <small>{c.email}</small>
                                          ) : null}
                                          {this.state.relatedToType ==
                                          "Lead" ? (
                                            <small>
                                              {c.name
                                                ? c.email
                                                : `Source: ${c.initialSource}`}
                                            </small>
                                          ) : null}
                                          {this.state.relatedToType ==
                                          "Affiliate" ? (
                                            <small>{c.name}</small>
                                          ) : null}
                                        </p>
                                      </div>
                                      {i != this.state.customers?.length - 1 ? (
                                        <hr className="m-0"></hr>
                                      ) : null}
                                    </>
                                  ))}
                                {this.state.searchLoading ? (
                                  <>
                                    <div className="text-center py-4">
                                      <Spinner size="sm" color="dark"></Spinner>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </Popover>
                          }
                        >
                          <Input
                            bsSize="sm"
                            type="text"
                            name="search"
                            placeholder={`Search ${this.state.relatedToType?.toLowerCase()}s...`}
                            value={this.state.search}
                            onChange={this.handleSearchChange.bind(this)}
                          ></Input>
                        </Whisper>
                      )}
                    </FormGroup>
                  </>
                ) : null}
              </>
            )}
            <hr className="my-3"></hr>
            <h4 className="mb-3">Completion Information</h4>
            <Row className="align-items-top">
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>
                    Due Date
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <DatePicker
                    size="sm"
                    oneTap
                    required
                    block
                    placement="auto"
                    placeholder="Select A Date"
                    cleanable={false}
                    defaultValue={moment().toDate()}
                    value={this.state.dueDate}
                    onChange={(e) => {
                      this.setState({ dueDate: e });
                    }}
                    ranges={[
                      {
                        label: "Today",
                        value: moment().toDate(),
                        closeOverlay: true,
                      },
                      {
                        label: "Next Week",
                        value:
                          moment().day() == 6
                            ? moment().add(1, "week").toDate()
                            : moment().day(6).add(1, "week").toDate(),
                        closeOverlay: true,
                      },
                    ]}
                  ></DatePicker>
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Status</h5>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="status"
                    placeholder="Status"
                    value={this.state.status}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="Not Started">Not Started</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="On Hold">On Hold</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <FormGroup>
                  <h5>Assigned To</h5>
                  <SelectPicker
                    size="sm"
                    groupBy="group"
                    placement="auto"
                    loading={this.state.loadingCoaches}
                    cleanable={true}
                    placeholder={`Select A ${
                      !this.props.storeTask ? "Staff Member" : "Team Member"
                    }`}
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          if (b.groupTitle == "Franchise Team") {
                            return 1;
                          }

                          if (b.groupTitle == "Production Team") {
                            if (a.groupTitle == "Franchise Team") {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Store Admin Accounts") {
                            if (
                              a.groupTitle == "Franchise Team" ||
                              a.groupTitle == "Production Team"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          if (b.groupTitle == "Other Users") {
                            if (
                              a.groupTitle == "Franchise Team" ||
                              a.groupTitle == "Production Team" ||
                              a.groupTitle == "Store Admin Accounts"
                            ) {
                              return -1;
                            }

                            return 1;
                          }

                          return a.groupTitle < b.groupTitle ? -1 : 1;
                        };
                      }

                      return (a, b) => {
                        return a.name < b.name ? -1 : 1;
                      };
                    }}
                    data={this.state.coachOptions}
                    style={{}}
                    value={this.state.assignedTo}
                    block
                    onChange={(val) => {
                      this.setState({ assignedTo: val });
                    }}
                  />
                </FormGroup>
              </Col>
              {/*<Col xs="12" md="6">
                <FormGroup>
                  <h5>Best Time To Contact</h5>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="timeToContact"
                    placeholder="Best Time To Contact"
                    value={this.state.timeToContact}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="">--</option>
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                    <option value="Evening">Evening</option>
                  </Input>
                </FormGroup>
              </Col> */}
            </Row>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(TaskCreateDrawer);
