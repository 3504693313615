import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";

var globalRoutes = [
  /*{
    path: "/users",
    name: "Users",
    icon: "mdi mdi-account",
    dropdown: false,
  },*/
  {
    path: "/templates",
    name: "Templates",
    icon: "mdi mdi-pencil-ruler",
    dropdown: true,
    dropdownItems: [
      {
        name: "Conversation Templates",
        path: "/templates",
      },
      {
        path: "/templates/documents",
        name: "Document Templates",
      },
      {
        name: "NSO Timeline",
        path: "/templates/nso",
      },
      {
        name: "Purchase List",
        path: "/templates/nso/purchase-list",
      },
      {
        name: "Opening Checklist",
        path: "/templates/nso/opening",
      },
      {
        name: "Approved Retail List",
        path: "/templates/nso/retail-list",
      },
    ],
  },
  {
    path: "/app",
    name: "Marketing",
    icon: "mdi mdi-checkbox-blank-badge",
    dropdown: true,
    dropdownItems: [
      {
        name: "In-App Content",
        path: "/app",
      },
      {
        name: "Campaigns",
        path: "/app/campaigns",
      },
      {
        name: "Marketing Kits",
        path: "/app/marketing-kits",
      },
      {
        name: "Cohorts",
        path: "/app/cohorts",
      },
    ],
  },

  /*{
    path: "/discounts",
    name: "Discounts",
    icon: "mdi mdi-ticket-percent",
  },*/
  {
    path: "/product-categories",
    name: "Product Categories",
    icon: "mdi mdi-select-group",
  },

  {
    path: "/url-creator",
    name: "Link Generator",
    icon: "mdi mdi-link",
  },
];

var reportRoutes = [
  {
    path: "/scorecard",
    name: "Scorecard",
    icon: "mdi mdi-counter",
  },
  {
    path: "/business-review",
    name: "Business Review",
    icon: "mdi mdi-calendar-multiple-check",
    globalOnly: true,
  },
  {
    path: "/sales",
    name: "Sales Summary",
    icon: "mdi mdi-cash-fast",
  },
  {
    path: "/fulfillment",
    name: "Fulfillment",
    icon: "mdi mdi-shipping-pallet",
    dropdown: true,
    dropdownItems: [
      {
        name: "Summary",
        path: "/fulfillment",
      },
      {
        name: "Per-Week Details",
        path: "/fulfillment/detail",
      },
      {
        name: "Projections",
        path: "/fulfillment/projections",
        globalOnly: true,
      },
      /*{
        name: "All Stores",
        path: "/fulfillment/stores",
      },
      {
        name: "Test Reports",
        path: "/fulfillment/testing",
      },*/
    ],
  },
  /**
   * {
    path: "/leads",
    name: "Leads",
    icon: "mdi mdi-account-filter",
    dropdown: true,
    dropdownItems: [
      {
        name: "Summary",
        path: "/leads",
      },
      /*{
        name: "All Stores",
        path: "/fulfillment/stores",
      },
      {
        name: "Test Reports",
        path: "/fulfillment/testing",
      }
    ],
  },
   */
  {
    path: "/franleads",
    name: "FranLeads",
    icon: "mdi mdi-domain",
    globalOnly: true,
  },
  {
    path: "/marketing-campaigns",
    name: "Marketing Campaigns",
    icon: "mdi mdi-bullhorn-variant",
    globalOnly: true,
  },
];

var accountRoutes = [
  {
    path: "/information",
    name: "Account Information",
    icon: "mdi mdi-account",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "mdi mdi-logout",
    onClick: (props) => {
      console.log(props);

      LocalStorage.set(Constant.CACHE.TOKEN, null);
      window[Constant.CACHE.USER] = null;
      LocalStorage.set(Constant.CACHE.USER, null);

      props.history.push("/auth");
    },
  },
];

var productionRoutes = [
  /*{
    path: "/home",
    name: "Home",
    icon: "mdi mdi-home",
  },*/
  {
    path: "https://eos.ninety.io/home",
    name: "Level 10",
    icon: "mdi mdi-numeric-10-box",
    external: true,
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "mdi mdi-shipping-pallet",
    dropdown: true,
    dropdownItems: [
      {
        name: "All Orders",
        path: "/orders",
      },
      {
        name: "Pricing & Quantities",
        path: "/orders/pricing",
      },
    ],
  },
  {
    path: "/quality",
    name: "Claims & Credits",
    icon: "mdi mdi-alert-box",
  },
  {
    path: "/fulfillment",
    name: "Fulfillment Reports",
    icon: "mdi mdi-shipping-pallet",
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: "mdi mdi-format-list-checks",
  },
  {
    path: "/tickets",
    name: "Support Tickets",
    icon: "mdi mdi-lifebuoy",
  },
  {
    path: "/meals",
    name: "Meals",
    icon: "mdi mdi-food",
    dropdown: true,
    dropdownItems: [
      {
        name: "All Meals",
        path: "/meals",
      },
      {
        name: "Categories",
        path: "/meals/categories",
      },
      {
        name: "Allergens",
        path: "/meals/allergens",
      },
      {
        path: "/meals/menus",
        name: "Menus",
        icon: "mdi mdi-format-list-text",
      },
    ],
  },
];

var universityRoutes = [
  {
    path: "/library",
    name: "Resource Center",
    icon: "mdi mdi-library",
  },
  {
    path: "/assets",
    name: "Digital Assets",
    icon: "mdi mdi-play-box",
    dropdown: true,
    dropdownItems: [
      {
        name: "Visual Guides & Assets",
        path: "/assets",
      },
      {
        name: "Social Media Content",
        path: "/assets/social",
      },
      {
        name: "Content Calendar",
        path: "/assets/calendar",
      },
    ],
  },
];

var frandevRoutes = [
  {
    path: "/stores",
    name: "Stores",
    icon: "mdi mdi-store",
    dropdown: true,
    dropdownItems: [
      {
        name: "All Stores",
        path: "/stores",
      },
      {
        path: "/stores/territories",
        name: "Territories",
      },
      {
        name: "Billing Accounts",
        path: "/stores/billing-accounts",
      },
    ],
  },
  {
    path: "https://eos.ninety.io/home",
    name: "Level 10",
    icon: "mdi mdi-numeric-10-box",
    external: true,
  },
  {
    path: "/leads",
    name: "Leads",
    icon: "mdi mdi-account-filter",
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: "mdi mdi-format-list-checks",
  },
  {
    path: "/tickets",
    name: "Support Tickets",
    icon: "mdi mdi-lifebuoy",
  },
  {
    path: "/updates",
    name: "System Updates",
    icon: "mdi mdi-checkbox-blank-badge",
    dropdown: true,
    dropdownItems: [
      {
        name: "Notifications",
        path: "/updates",
      },
      {
        path: "/updates/banners",
        name: "In-App Banners",
      },
    ],
  },
];

var storefrontRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: "mdi mdi-home",
  },
  {
    path: "/members",
    name: "Members",
    icon: "mdi mdi-account-star",
  },
  {
    path: "/leads",
    name: "Leads",
    icon: "mdi  mdi-account-filter",
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: "mdi  mdi-format-list-checks",
  },
  {
    path: "/conversations",
    name: "Conversations",
    icon: "mdi mdi-forum",
    featureFlag: "",
    dataRequirement: "twilioNumber",
    dropdown: true,
    dropdownItems: [
      {
        name: "All Conversations",
        path: "/conversations",
      },
      {
        name: "Broadcasts",
        path: "/conversations/broadcasts",
      },
      {
        name: "Templates",
        path: "/conversations/templates",
      },
      {
        name: "Suppressed Contacts",
        path: "/conversations/suppressions",
      },
    ],
  },
  {
    path: "/affiliates",
    name: "Community Partners",
    icon: "mdi  mdi-account-heart",
    dropdown: false,
  },
  {
    path: "/fulfillment",
    name: "Fulfillment",
    icon: "mdi mdi-shipping-pallet",
    dropdown: true,
    dropdownItems: [
      {
        name: "Summary",
        path: "/fulfillment",
      },
      {
        name: "All Orders",
        path: "/fulfillment/orders",
      },
      {
        name: "Pickup List",
        path: "/fulfillment/pickup",
      },
      {
        name: "Delivery List",
        path: "/fulfillment/delivery",
      },
      {
        name: "Live Order Usage",
        path: "/fulfillment/projections",
        conditionalRender: (store) => {
          return store?.fulfillmentStrategy != "In-Store Fulfillment";
        },
      },
    ],
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: "mdi mdi-receipt",
  },
  {
    path: "/products",
    name: "Products",
    icon: "mdi mdi-tag",
    dropdown: true,

    dropdownItems: [
      {
        name: "Inventory",
        path: "/products",
      },
      {
        name: "Meal Menus",
        path: "/products/menus/meals",
      },
      {
        name: "Orders",
        path: "/products/orders",
      },
      {
        name: "Claims & Credits",
        path: "/products/quality",
      },
      {
        name: "Apparel",
        path: "http://proforma.com/projectleannationadvanced",
        external: true,
      },
      {
        name: "Retail Ingredients & Consumables",
        path: "/products/retail-list",
      },
      {
        name: "NSO / Equipment List",
        path: "/products/nso-list",
      },
      {
        name: "Approved Retail Items",
        path: "/products/retail-items",
      },
    ],
  },
  /*{
    path: "/products",
    name: "Products",
    icon: "mdi mdi-format-list-text",
    dropdown: true,
    dropdownItems: [
      {
        name: "Meals",
        path: "/products/meals",
      },
      {
        name: "Retail",
        path: "/products/retail",
      },
    ],
  },
  {
    path: "/discounts",
    name: "Discounts",
    icon: "mdi mdi-sale",
    dropdown: true,
    dropdownItems: [
      {
        name: "Discount Codes",
        path: "/discounts",
      },
      {
        name: "Automatic Discounts",
        path: "/discounts/automatic",
      },
    ],
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: "mdi mdi-chart-box",
    dropdown: true,
    dropdownItems: [
      {
        name: "Dashboard",
        path: "/analytics",
      },
      {
        name: "Reports",
        path: "/analytics/reports",
      },
    ],
  },

  {
    path: "/finances",
    name: "Finances",
    icon: "mdi mdi-cash-multiple",
    dropdown: true,
    dropdownItems: [
      {
        name: "Overview",
        path: "/finances",
      },
      {
        name: "Fees",
        path: "/finances/fees",
      },
    ],
  },
  */

  {
    path: "/settings",
    name: "Settings",
    icon: "mdi mdi-cog",
    dropdown: true,
    dropdownItems: [
      {
        name: "Online Presence",
        path: "/settings",
      },
      {
        name: "Staff Members",
        path: "/settings/staff",
      },
      {
        name: "Coupons",
        path: "/settings/coupons",
      },
      {
        name: "Payment Processing",
        path: "/settings/payment-processing",
      },
      {
        name: "Franchise Territories",
        path: "/settings/territories",
      },
      {
        name: "Manage Users",
        path: "/settings/users",
      },
    ],
  },
  {
    path: "/support/franchise",
    name: "Franchise Support",
    icon: "mdi mdi-lifebuoy",
  },
  {
    path: "https://forms.gle/AFMoNfGFANjAr7pL9",
    external: true,
    name: "FAC Submission",
    icon: "mdi mdi-lightbulb-on",
  },
];

export default {
  globalRoutes,
  reportRoutes,
  productionRoutes,
  storefrontRoutes,
  universityRoutes,
  frandevRoutes,
  accountRoutes,
};
