import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
  Checkbox,
  Uploader,
  TagInput,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import LinkUtils from "lib/LinkUtils";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import FileUploadDrawer from "../FileUploadDrawer";

class NewContentDrawer extends React.Component {
  state = {
    type: "",
    scheduledFor: moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .millisecond(0)
      .toDate(),
    name: "",
    detailsOpen: true,
    description: "",
    assets: [],
    shareable: true,
    platforms: [],
    postTypes: [],
    audiences: [],
    pendingFiles: [],
    activeAsset: null,
    tags: [],
    typeOptions: [
      {
        label: "Social Media",
        value: "Social Media",
      },
      {
        label: "Email",
        value: "Email",
      },
      {
        label: "Broadcast",
        value: "Broadcast",
      },
    ],
    platformOptions: [
      {
        label: "Instagram",
        value: "Instagram",
      },
      {
        label: "Facebook",
        value: "Facebook",
      },
    ],
    postTypeOptions: [
      {
        label: "Story",
        value: "Story",
      },
      {
        label: "Reel",
        value: "Reel",
      },
      {
        label: "Post",
        value: "Post",
      },
    ],
    audienceOptions: [
      {
        label: "Actives",
        value: "Actives",
      },
      {
        label: "HFNs",
        value: "HFNs",
      },
      {
        label: "Leads",
        value: "Leads",
      },
    ],
  };

  _loadContentAssets(assets) {
    let out = assets?.length
      ? assets?.map((asset) => {
          return {
            title: asset?.title ? asset?.title : "",
            description: asset?.description ? asset?.description : "",
            caption: asset?.caption ? asset?.caption : "",
            file: asset?.file ? asset?.file : null,
            editorsNotes: asset?.editorsNotes ? asset?.editorsNotes : "",
            _id: asset?._id ? asset?._id : StringUtils.uuid(),
            editorsNotesObj: null,
          };
        })
      : [];

    for (let i = 0; i < out?.length; i++) {
      if (out[i]?.editorsNotes) {
        const contentBlock = htmlToDraft(out[i]?.editorsNotes);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const contentState2 = EditorState.createWithContent(contentState);

          out[i].editorsNotesObj = contentState2;
        } else {
          out[i].editorsNotesObj = EditorState.createEmpty();
        }
      } else {
        out[i].editorsNotesObj = EditorState.createEmpty();
      }
    }

    this.setState({
      assets: out,
      activeAsset: assets?.length ? 0 : null,
    });
  }

  _loadContentProps() {
    this.setState({
      name: this.props.content?.name,
      description: this.props.content?.description,

      type: this.props.content?.type,
      tags: this.props.content?.tags?.length ? this.props.content?.tags : [],
      shareable: this.props.content?.shareable,
      platforms: this.props.content?.platforms,
      postTypes: this.props.content?.postTypes,
      scheduledFor: this.props.content?.scheduledFor
        ? moment(this.props.content?.scheduledFor).toDate()
        : null,
      audiences: this.props.content?.audiences,
      activeAsset: this.state.assets?.length ? 0 : null,
    });

    this._loadContentAssets(this.props.content?.assets);
  }

  _clearContentOptions() {
    this.setState({
      name: "",
      description: "",
      assets: [],
      type: null,
      tags: [],
      shareable: true,
      platforms: [],
      postTypes: [],
      scheduledFor: moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .millisecond(0)
        .toDate(),
      audiences: [],
      activeAsset: null,
      pendingFiles: [],
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.content != prevProps.content && this.props.content) {
      this._loadContentProps();
    }

    if (this.props.open != prevProps?.open && this.props.open) {
      if (this.props.content) {
        this.setState({
          detailsOpen: false,
        });
      } else {
        this.setState({
          detailsOpen: true,
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.content) {
      this._loadContentProps();
    }
  }

  toggleModal() {
    this._clearContentOptions();

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  create() {
    this.setState({ submitting: true });

    let assets = this.state.assets?.map((asset) => {
      let content = draftToHtml(
        convertToRaw(asset?.editorsNotesObj?.getCurrentContent())
      );

      asset.editorsNotes = content?.length ? content : "";

      asset.editorsNotesPlainText = asset.editorsNotes
        .replace(/<[^>]+>/g, "")
        .replace(/^\s+|\s+$/g, "");

      return asset;
    });

    let payload = {
      type: this.state.type,
      tags: this.state.tags,
      platforms: this.state.platforms,
      scheduledFor: this.state.scheduledFor,
      name: this.state.name,
      description: this.state.description,
      assets,
      shareable: this.state.shareable,
      postTypes: this.state.postTypes,
      audiences: this.state.audiences,
    };

    APIV2.addContentCalendarItem(payload)
      .then(
        (data) => {
          const content = data.data.content;

          this.toggleModal();

          PubSub.publish(Event.CONTENT.CREATED, content);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              `Unable to add content to calendar - unknown error occurred. Try again.`
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({
      submitting: true,
    });

    let assets = this.state.assets?.map((asset) => {
      console.log(asset);

      let content = draftToHtml(
        convertToRaw(asset?.editorsNotesObj?.getCurrentContent())
      );

      asset.editorsNotes = content?.length ? content : "";

      asset.editorsNotesPlainText = asset.editorsNotes
        .replace(/<[^>]+>/g, "")
        .replace(/^\s+|\s+$/g, "");

      return asset;
    });

    let payload = {
      type: this.state.type,
      tags: this.state.tags,
      platforms: this.state.platforms,
      scheduledFor: this.state.scheduledFor,
      name: this.state.name,
      description: this.state.description,
      assets,
      shareable: this.state.shareable,
      postTypes: this.state.postTypes,
      audiences: this.state.audiences,
    };

    APIV2.modifyContentCalendarItem(this.props.content?._id, payload)
      .then(
        (data) => {
          const content = data.data.content;

          this.toggleModal();

          PubSub.publish(Event.CONTENT.MODIFIED, content);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              `Unable to modify content calendar item - unknown error occurred. Try again.`
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  /**
   * Adds a new default asset
   */
  addAsset() {
    let assets = this.state.assets?.length ? this.state.assets : [];

    assets.push({
      _id: StringUtils.uuid(),
      title: "",
      caption: "",
      file: null,
      editorsNotes: "",
      editorsNotesObj: EditorState.createEmpty(),
      description: "",
    });

    this.setState({
      assets,
      activeAsset: assets?.length - 1,
    });
  }

  /**
   * Handles changes to asset input fields
   *
   * @param {*} idx
   * @param {*} e
   * @returns
   */
  handleAssetInputChange(idx, e) {
    const { name, value } = e.target;

    let asset = this.getActiveAsset(idx);

    if (!asset) {
      return;
    }

    asset[name] = value;

    let assets = this.state.assets;

    assets[idx] = asset;

    this.setState({
      assets,
    });
  }

  /**
   * Fetches the active asset by index.
   *
   * @param {*} idx
   * @returns
   */
  getActiveAsset(idx) {
    if (idx < 0 || idx > this.state.assets?.length) {
      return null;
    }

    return this.state.assets[idx];
  }

  /**
   * Removes the uploaded file from an asset
   *
   * @param {*} idx
   * @returns
   */
  removeFileFromAsset(idx) {
    let asset = this.getActiveAsset(idx);

    if (!asset) {
      return;
    }

    swal({
      title: "Remove Asset File",
      text: `Are you sure you want to remove uploaded file from the "${asset?.title}" asset?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      asset.file = null;

      let assets = this.state.assets;

      assets[idx] = asset;

      this.setState({
        assets,
      });
    });
  }

  /**
   * Deletes an asset entirely
   *
   * @param {*} idx
   * @returns
   */
  deleteAsset(idx) {
    let asset = this.getActiveAsset(idx);

    if (!asset?._id) {
      return;
    }

    swal({
      title: "Remove Asset",
      text: `Are you sure you want to remove the "${
        asset?.title ? asset?.title : "(empty title)"
      }" asset?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      let assets = _.filter(this.state.assets, (a) => {
        return a?._id != asset?._id;
      });

      let activeAsset = this.state.activeAsset - 1;

      if (activeAsset < 0) {
        activeAsset = 0;
      }

      if (!assets?.length) {
        activeAsset = null;
      }

      this.setState({
        assets,
        activeAsset,
      });
    });
  }

  onAssetContentChange(assetIDX, editorState) {
    let assets = this.state.assets;

    let activeAsset = this.getActiveAsset(assetIDX);

    activeAsset.editorsNotesObj = editorState;

    assets[assetIDX] = activeAsset;

    this.setState({
      assets,
    });
  }

  uploadCallback(file) {
    return new Promise((resolve, reject) => {
      if (file) {
        const data = new FormData();

        data.append("file", file);

        APIV2.uploadCDNImage(data).then(
          (data) => {
            let url = data.data.data.url;

            console.log(url);

            resolve({ data: { link: url } });
          },
          (e) => {
            reject(e);
          }
        );
      }
    });
  }

  async uploadAssetFile(idx, file) {
    let asset = this.getActiveAsset(idx);

    if (!asset || !file) {
      return;
    }

    asset.file = file;

    let assets = this.state.assets;

    assets[idx] = asset;

    this.setState({
      assets,
      pendingFiles: [],
    });
  }

  reorderAssets(asset, idx, direction) {
    if (
      idx + direction < 0 ||
      idx + direction > this.state.reorderAssets?.length - 1
    ) {
      return;
    }

    const swapAssetIDX = idx + direction;
    const swapAsset = this.state.reorderAssets[swapAssetIDX];

    let assets = this.state.reorderAssets;

    assets[idx] = swapAsset;
    assets[swapAssetIDX] = asset;

    this.setState({
      reorderAssets: assets,
    });
  }

  validAssets(assets) {
    if (!assets?.length) {
      if (this.state.type == "Social Media" || this.state.shareable) {
        return false;
      }

      return true;
    }

    for (let i = 0; i < assets?.length; i++) {
      const asset = assets[i];

      if (!asset?.title || (this.state.shareable && !asset?.file?.url)) {
        return false;
      }
    }

    return true;
  }

  render() {
    return (
      <>
        <Drawer
          size="lg"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.content ? "Modify" : "Add"} Content
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.scheduledFor ||
                  !this.state.name ||
                  !this.state.type ||
                  !this.validAssets(this.state.assets)
                }
                color="primary"
                onClick={() => {
                  this.props.content ? this.modify() : this.create();
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.content ? "Save" : "Add"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                +<Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <Card
              className={`border ${this.state.detailsOpen ? "shadow" : ""}`}
            >
              <CardHeader
                className="cursor-pointer p-3"
                onClick={() => {
                  this.setState({
                    detailsOpen: !this.state.detailsOpen,
                  });
                }}
              >
                <Row className="align-items-center">
                  <Col xs="" style={{ width: "calc(100% - 100px)" }}>
                    <h3 className="text-truncate m-0 text-dark">
                      {this.state.name ? this.state.name : "Content"}
                      &nbsp;Details
                    </h3>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      outline
                      color="dark"
                      className="border-0 btn-icon-only"
                    >
                      <i
                        className={`mdi mdi-chevron-${
                          this.state.detailsOpen ? "up" : "down"
                        }`}
                      ></i>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Collapse isOpen={this.state.detailsOpen}>
                <CardBody className="p-3">
                  <FormGroup>
                    <h5>
                      Content Type
                      <i
                        className="mdi mdi-octagram text-danger pl-1"
                        style={{
                          fontSize: "80%",
                          position: "relative",
                          top: -1,
                        }}
                      ></i>
                    </h5>
                    <SelectPicker
                      searchable={false}
                      oneTap
                      data={this.state.typeOptions}
                      value={this.state.type}
                      block
                      preventOverflow={true}
                      placeholder="Select A Type"
                      onChange={(v) => {
                        this.setState({ type: v });
                      }}
                      placement="autoVerticalStart"
                    ></SelectPicker>
                  </FormGroup>
                  {this.state.type == "Social Media" ? (
                    <>
                      <FormGroup>
                        <h5>
                          Target Platforms
                          <i
                            className="mdi mdi-octagram text-danger pl-1"
                            style={{
                              fontSize: "80%",
                              position: "relative",
                              top: -1,
                            }}
                          ></i>
                        </h5>
                        <CheckPicker
                          required
                          block
                          searchable={false}
                          placeholder="Select Platforms"
                          data={this.state.platformOptions}
                          placement="autoVerticalStart"
                          preventOverflow
                          value={this.state.platforms}
                          onChange={(v) => {
                            this.setState({
                              platforms: v,
                            });
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <h5>
                          Post Type
                          <i
                            className="mdi mdi-octagram text-danger pl-1"
                            style={{
                              fontSize: "80%",
                              position: "relative",
                              top: -1,
                            }}
                          ></i>
                        </h5>
                        <CheckPicker
                          required
                          block
                          searchable={false}
                          placeholder="Select Post Types"
                          data={this.state.postTypeOptions}
                          placement="autoVerticalStart"
                          preventOverflow
                          value={this.state.postTypes}
                          onChange={(v) => {
                            this.setState({
                              postTypes: v,
                            });
                          }}
                        />
                      </FormGroup>
                    </>
                  ) : null}
                  {this.state.type == "Email" ||
                  this.state.type == "Broadcast" ? (
                    <>
                      <FormGroup>
                        <h5>
                          Target Audiences
                          <i
                            className="mdi mdi-octagram text-danger pl-1"
                            style={{
                              fontSize: "80%",
                              position: "relative",
                              top: -1,
                            }}
                          ></i>
                        </h5>
                        <CheckPicker
                          required
                          block
                          searchable={false}
                          placeholder="Select Audiences"
                          data={this.state.audienceOptions}
                          placement="autoVerticalStart"
                          preventOverflow
                          value={this.state.audiences}
                          onChange={(v) => {
                            this.setState({
                              audiences: v,
                            });
                          }}
                        />
                      </FormGroup>
                    </>
                  ) : null}
                  <FormGroup>
                    <h5>
                      Scheduled For
                      <i
                        className="mdi mdi-octagram text-danger pl-1"
                        style={{
                          fontSize: "80%",
                          position: "relative",
                          top: -1,
                        }}
                      ></i>
                    </h5>
                    <DatePicker
                      required
                      block
                      placeholder="Select A Date & Time"
                      value={this.state.scheduledFor}
                      format="MM/dd/yy hh:mm aa"
                      showMeridian
                      placement="autoVerticalStart"
                      onChange={(e) => {
                        this.setState({
                          scheduledFor: e,
                        });
                      }}
                      ranges={[]}
                    ></DatePicker>
                    <div className="text-muted mt-1 small">
                      Timezone:{" "}
                      {Intl.DateTimeFormat().resolvedOptions().timeZone}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <h5>
                      Shareable By Franchise Partner
                      <i
                        className="mdi mdi-octagram text-danger pl-1"
                        style={{
                          fontSize: "80%",
                          position: "relative",
                          top: -1,
                        }}
                      ></i>
                    </h5>
                    <Checkbox
                      checked={this.state.shareable}
                      onChange={(e, v) => {
                        this.setState({
                          shareable: v,
                        });
                      }}
                    >
                      Shareable
                    </Checkbox>
                  </FormGroup>
                  <FormGroup>
                    <h5>
                      Content/Campaign Name
                      <i
                        className="mdi mdi-octagram text-danger pl-1"
                        style={{
                          fontSize: "80%",
                          position: "relative",
                          top: -1,
                        }}
                      ></i>
                    </h5>
                    <Input
                      type="text"
                      name="name"
                      onChange={this.handleInputChange.bind(this)}
                      value={this.state.name}
                      placeholder="Name"
                      bsSize="sm"
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <h5>Description</h5>
                    <Input
                      type="textarea"
                      name="description"
                      onChange={this.handleInputChange.bind(this)}
                      value={this.state.description}
                      placeholder="Description"
                      bsSize="sm"
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <h5>
                      Tags
                      <i
                        className="mdi mdi-octagram text-danger pl-1"
                        style={{
                          fontSize: "80%",
                          position: "relative",
                          top: -1,
                        }}
                      ></i>
                    </h5>
                    <TagInput
                      value={this.state.tags}
                      block
                      preventOverflow={true}
                      placeholder="Add Tags"
                      onChange={(v) => {
                        const val = v?.map((val) => {
                          return val?.toLowerCase();
                        });

                        this.setState({ tags: val });
                      }}
                      placement="autoVerticalStart"
                      trigger={["Comma", "Enter"]}
                    />
                  </FormGroup>
                </CardBody>
              </Collapse>
            </Card>
            <Card className="mt-4 border">
              <CardHeader className="cursor-pointer p-3">
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="text-truncate m-0 text-dark">
                      Content Assets
                    </h3>
                  </Col>
                  <Col xs="auto">
                    {this.state.reorder ? (
                      <>
                        <Button
                          size="sm"
                          outline
                          color="dark"
                          onClick={() => {
                            this.setState({
                              reorder: false,
                              reorderAssets: null,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              reorderAssets: null,
                              assets: this.state.reorderAssets,
                              reorder: false,
                            });
                          }}
                        >
                          Save Order
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          size="sm"
                          outline
                          color="dark"
                          className="border-0 btn-icon-only"
                          disabled={this.state.assets?.length < 2}
                          onClick={() => {
                            this.setState({
                              reorder: true,
                              reorderAssets: this.state.assets.map((asset) => {
                                return _.clone(asset);
                              }),
                            });
                          }}
                        >
                          <i className={`mdi mdi-file-swap-outline`}></i>
                        </Button>
                        <Button
                          size="sm"
                          color="primary"
                          className="btn-icon-only"
                          onClick={() => {
                            this.addAsset();
                          }}
                        >
                          <i className={`mdi mdi-plus`}></i>
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-3">
                {this.state.activeAsset === null ? (
                  <>
                    <div className="rounded border px-3 py-5">
                      Get started by adding your first asset.
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.reorder ? (
                      <>
                        {this.state.reorderAssets?.map((asset, i) => (
                          <div
                            className="p-2 rounded border mb-2"
                            key={asset?._id}
                          >
                            <Row className="align-items-center">
                              <Col xs="">
                                <p className="m-0 text-dark">
                                  {asset?.title
                                    ? asset?.title
                                    : `Asset ${i + 1}: (empty title)`}
                                </p>
                              </Col>
                              <Col xs="auto">
                                <Row className="align-items-center">
                                  <Col xs="12" sm="auto" className="pr-sm-0">
                                    <Button
                                      disabled={i == 0}
                                      size="sm"
                                      outline
                                      color="dark"
                                      className="btn-icon-only"
                                      onClick={() => {
                                        this.reorderAssets(asset, i, -1);
                                      }}
                                    >
                                      <i className="mdi mdi-chevron-up"></i>
                                    </Button>
                                  </Col>
                                  <Col xs="12" sm="auto">
                                    <Button
                                      disabled={
                                        i ==
                                        this.state.reorderAssets?.length - 1
                                      }
                                      size="sm"
                                      outline
                                      color="dark"
                                      className="btn-icon-only"
                                      onClick={() => {
                                        this.reorderAssets(asset, i, 1);
                                      }}
                                    >
                                      <i className="mdi mdi-chevron-down"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <Row className="align-items-center border-bottom pb-3 mb-3">
                          <Col xs="">
                            <h4 className="m-0">
                              Asset {this.state.activeAsset + 1}
                            </h4>
                          </Col>
                          <Col xs="auto">
                            <Button
                              onClick={() => {
                                this.deleteAsset(this.state.activeAsset);
                              }}
                              size="sm"
                              outline
                              color="danger"
                              className="btn-icon-only"
                            >
                              <i className="mdi mdi-trash-can"></i>
                            </Button>
                          </Col>
                        </Row>
                        <FormGroup>
                          <h5>
                            Asset Title
                            <i
                              className="mdi mdi-octagram text-danger pl-1"
                              style={{
                                fontSize: "80%",
                                position: "relative",
                                top: -1,
                              }}
                            ></i>
                          </h5>
                          <Input
                            type="text"
                            name="title"
                            onChange={(e) => {
                              this.handleAssetInputChange(
                                this.state.activeAsset,
                                e
                              );
                            }}
                            value={
                              this.getActiveAsset(this.state.activeAsset)?.title
                            }
                            placeholder="Title"
                            bsSize="sm"
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <h5>Asset Description</h5>
                          <Input
                            type="textarea"
                            name="description"
                            onChange={(e) => {
                              this.handleAssetInputChange(
                                this.state.activeAsset,
                                e
                              );
                            }}
                            value={
                              this.getActiveAsset(this.state.activeAsset)
                                ?.description
                            }
                            placeholder="Description"
                            bsSize="sm"
                          ></Input>
                        </FormGroup>
                        {this.state.type == "Social Media" ? (
                          <FormGroup>
                            <h5>Caption</h5>
                            <Input
                              type="textarea"
                              name="caption"
                              onChange={(e) => {
                                this.handleAssetInputChange(
                                  this.state.activeAsset,
                                  e
                                );
                              }}
                              value={
                                this.getActiveAsset(this.state.activeAsset)
                                  ?.caption
                              }
                              placeholder="Caption"
                              bsSize="sm"
                            ></Input>
                          </FormGroup>
                        ) : null}
                        <FormGroup>
                          <div className="m-2">
                            <h5>
                              Asset File
                              <i
                                className="mdi mdi-octagram text-danger pl-1"
                                style={{
                                  fontSize: "80%",
                                  position: "relative",
                                  top: -1,
                                }}
                              ></i>
                            </h5>
                            {this.getActiveAsset(this.state.activeAsset)
                              ?.file ? (
                              <>
                                <div className="p-2 text-dark rounded border">
                                  <Row className="align-items-center">
                                    <Col xs="12" md="">
                                      <p className="m-0">
                                        {
                                          this.getActiveAsset(
                                            this.state.activeAsset
                                          )?.file?.fileName
                                        }
                                      </p>
                                    </Col>
                                    <Col
                                      xs="12"
                                      md=""
                                      className="mt-3 mt-md-0 text-right pl-md-0"
                                    >
                                      <Button
                                        color="dark"
                                        outline
                                        className="btn-icon-only"
                                        onClick={() => {
                                          LinkUtils.openPrivateURL(
                                            this.getActiveAsset(
                                              this.state.activeAsset
                                            )?.file?.url
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-eye"></i>
                                      </Button>
                                      <Button
                                        color="danger"
                                        outline
                                        className="btn-icon-only"
                                        onClick={() => {
                                          this.removeFileFromAsset(
                                            this.state.activeAsset
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-close"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ) : (
                              <>
                                <Button
                                  outline
                                  color="primary"
                                  onClick={() => {
                                    this.setState({
                                      openUploader: true,
                                    });
                                  }}
                                >
                                  Upload Asset
                                </Button>
                              </>
                            )}
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <h5>Editor's Notes</h5>
                          <Editor
                            placeholder="Add content here..."
                            toolbarClassName="pln-richtext-toolbar"
                            editorClassName="pln-richtext-editor"
                            toolbar={{
                              options: [
                                "inline",
                                "blockType",
                                "fontSize",
                                "list",
                                "textAlign",
                                "colorPicker",
                                "link",
                                "embedded",
                                "emoji",
                                "image",
                                "remove",
                                "history",
                              ],
                              image: {
                                uploadEnabled: true,
                                uploadCallback: this.uploadCallback.bind(this),
                                alignmentEnabled: false,
                                previewImage: true,
                                inputAccept:
                                  "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                                alt: { present: false, mandatory: false },
                                defaultSize: {
                                  height: "auto",
                                  width: "60%",
                                },
                              },
                              embedded: {
                                className: undefined,
                                component: undefined,
                                popupClassName: undefined,
                                embedCallback: undefined,
                                defaultSize: {
                                  height: "auto",
                                  width: "60%",
                                },
                              },
                            }}
                            editorState={
                              this.getActiveAsset(this.state.activeAsset)
                                ?.editorsNotesObj
                            }
                            onEditorStateChange={(v) => {
                              this.onAssetContentChange(
                                this.state.activeAsset,
                                v
                              );
                            }}
                          ></Editor>
                        </FormGroup>
                      </>
                    )}
                  </>
                )}
              </CardBody>
              {!this.state.reorder ? (
                <CardFooter className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <p className="m-0">
                        {this.state.assets?.length ? (
                          <>
                            Viewing {this.state.activeAsset + 1} of&nbsp;
                            {this.state.assets?.length} assets
                          </>
                        ) : (
                          <>No assets</>
                        )}
                      </p>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        outline
                        color="dark"
                        disabled={
                          this.state.activeAsset == 0 ||
                          !this.state.assets?.length
                        }
                        className="btn-icon-only"
                        onClick={() => {
                          this.setState({
                            activeAsset:
                              this.state.activeAsset - 1 <= 0
                                ? 0
                                : this.state.activeAsset - 1,
                          });
                        }}
                      >
                        <i className="mdi mdi-chevron-left"></i>
                      </Button>
                      <Button
                        size="sm"
                        outline
                        color="dark"
                        disabled={
                          this.state.activeAsset ==
                            this.state.assets?.length - 1 ||
                          !this.state.assets?.length
                        }
                        className="btn-icon-only"
                        onClick={() => {
                          this.setState({
                            activeAsset:
                              this.state.activeAsset + 1 >=
                              this.state.assets?.length - 1
                                ? this.state.assets?.length - 1
                                : this.state.activeAsset + 1,
                          });
                        }}
                      >
                        <i className="mdi mdi-chevron-right"></i>
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              ) : null}
            </Card>
          </Drawer.Body>
        </Drawer>
        <FileUploadDrawer
          open={this.state.openUploader}
          singleFile={true}
          onComplete={(files) => {
            console.log(files);

            if (files?.length) {
              this.uploadAssetFile(this.state.activeAsset, files[0]);
            }

            this.setState({
              openUploader: false,
            });
          }}
        ></FileUploadDrawer>
      </>
    );
  }
}

export default withRouter(NewContentDrawer);
