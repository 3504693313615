import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
  Tooltip,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import LinkUtils from "../../lib/LinkUtils";
import EmojiPicker from "emoji-picker-react";
import autosize from "autosize";
import { count } from "sms-length";
import { SegmentedMessage } from "sms-segments-calculator";
import LocalStorage from "lib/LocalStorage";

class ConversationBroadcastDrawer extends React.Component {
  state = {
    message: "",
    name: "",
    list: "",
    scheduledSend: null,
    listOptions: [
      {
        value: "dynamic:all_actives",
        label: "All Active Members",
        group: "Members",
      },
      {
        value: `dynamic:all_actives_this_week`,
        label: `Active This Week (${moment().day(6).format("MMM DD, YYYY")})`,
        group: "Members",
      },
      {
        value: `dynamic:delivery_actives_this_week_${moment()
          .day(6)
          .format("YYYY-MM-DD")}`,
        label: `Active This Week - Delivery List (${moment()
          .day(6)
          .format("MMM DD, YYYY")})`,
        group: "Members",
      },
      {
        value: `dynamic:pickup_actives_this_week_${moment()
          .day(6)
          .format("YYYY-MM-DD")}`,
        label: `Active This Week - Pickup List (${moment()
          .day(6)
          .format("MMM DD, YYYY")})`,
        group: "Members",
      },
      {
        value: "dynamic:all_hfns",
        label: "All HFNs",
        group: "HFNs",
      },
      {
        value: "dynamic:all_leads",
        label: "All Leads",
        group: "Leads",
      },
      {
        value: "dynamic:all_retail_customers",
        label: "All Retail Customers (Members & Non-Members)",
        group: "Retail Loyalty Customers",
      },
      {
        value: "dynamic:retail_customers_last_30",
        label: "Retail Customers: Last Loyalty Purchase Within 30 Days",
        group: "Retail Loyalty Customers",
      },
      {
        value: "dynamic:retail_customers_over_30",
        label: "Retail Customers: Last Loyalty Purchase 30+ Days Ago",
        group: "Retail Loyalty Customers",
      },
      {
        value: "dynamic:retail_customers_first_time",
        label: "First Time Loyalty Customers (1 Purchase)",
        group: "Retail Loyalty Customers",
      },
      {
        value: "dynamic:retail_customers_repeat",
        label: "Repeat Loyalty Customers (3+ Purchases)",
        group: "Retail Loyalty Customers",
      },
    ],
    results: {},
    pagination: {},
    templates: [],
  };

  loadTemplates(storeID, page, count, filters, orderBy) {
    this.setState({
      loadingTemplates: true,
    });

    APIV2.getConversationTemplatesForStore(
      storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then((data) => {
        let templates = data.data.templates;

        this.setState({
          templates,
          pagination: data.data.pagination,
          results: data.data.result,
        });
      })
      .finally(() => {
        this.setState({
          loadingTemplates: false,
        });
      });
  }

  constructor(props) {
    super(props);

    this.emojiRef = React.createRef();
    this.quickReplyRef = React.createRef();
    this.searchRef = React.createRef();
  }

  performCustomListSearch(query) {
    APIV2.simpleSearchConversations(1, 40, this.props.store?._id, query)
      .then(
        (data) => {
          let conversations = data.data.conversations;

          if (conversations?.length) {
            const members = _.filter(conversations, (c) => {
              return c?.customerID;
            });

            const leads = _.filter(conversations, (c) => {
              return !c?.customerID;
            });

            this.setState({
              customSearchResults: {
                members,
                leads,
              },
            });
          } else {
            this.setState({
              customSearchResults: {
                members: [],
                leads: [],
              },
            });
          }
        },
        (e) => {
          console.error(e);

          this.setState({
            customSearchResults: {
              members: [],
              leads: [],
            },
          });
        }
      )
      .finally(() => {
        this.setState({
          customSearchLoading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.broadcast != prevProps?.broadcast && this.props.broadcast) {
      this.setState({
        name:
          this.props.broadcast?.name + (this.props.duplicate ? " Copy" : ""),
        message: this.props.broadcast?.message,
        lists: this.props.duplicate
          ? []
          : this.props.broadcast?.lists?.length
          ? this.props.broadcast?.lists
          : [],
        scheduledSend: this.props.broadcast.sendImmediately
          ? "now"
          : "scheduled",
        scheduledDate: moment(this.props.broadcast?.dateTime)
          .hours(0)
          .seconds(0)
          .minutes(0)
          .millisecond(0)
          .toDate(),
        scheduledTime: moment(this.props.broadcast?.dateTime).toDate(),
        customList: this.props.broadcast?.customList?.length
          ? this.props.broadcast?.customList
          : [],
        recipients: this?.props?.broadcast?.customList?.length
          ? "custom"
          : "list",
        mediaUrl: this.props.broadcast?.mediaUrl
          ? this.props.broadcast?.mediaUrl
          : "",
      });

      if (this.props.broadcast?.message?.length) {
        this.setState({
          messageLength: count(this.props.broadcast?.message),
        });
      } else {
        this.setState({
          messageLength: null,
        });
      }
    }

    if (this.props.store != prevProps.store && this.props.store) {
      let links = [
        {
          name: "Website: Store Location Page",
          url: `https://projectleannation.com/locations/${StringUtils.getStoreSlug(
            this.props.store
          )}`,
        },
        {
          name: "Member: Schedule Member Check-In",
          url: `https://account.projectleannation.com/schedule/${this.props.store?._id}/ec-consult`,
        },
        {
          name: "Member: Manage Account",
          url: `https://account.projectleannation.com/manage`,
        },
        {
          name: "Lead: Schedule New Client Consultation",
          url: `https://account.projectleannation.com/schedule/${this.props.store?._id}/nc-consult`,
        },
        {
          name: "Lead: Online Checkout",
          url: `https://account.projectleannation.com/start?store_id=${this?.props?.store?._id}`,
        },
        {
          name: "App Download Link",
          url: "https://pln.fit/download",
        },
      ];

      if (this.props.store?.googleMapsReviewURL) {
        links.push({
          name: "Google Maps: Review URL",
          url: this.props.store?.googleMapsReviewURL,
        });
      }

      this.setState({
        quickLinks: links,
      });
    }
  }

  estimateMessageCost(messageLength) {
    if (!messageLength) {
      return StringUtils.centsToCurrency(0);
    }

    let cost = messageLength?.messages * Constant.TWILIO_MESSAGE_COST;

    cost = Math.ceil(cost * 100) / 100;

    return StringUtils.centsToCurrency(cost * 100);
  }

  componentDidMount() {
    if (this.props.broadcast) {
      this.setState({
        name:
          this.props.broadcast?.name + (this.props.duplicate ? " Copy" : ""),
        message: this.props.broadcast?.message,
      });
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      message: "",
      lists: [],
      mediaUrl: "",
      scheduledDate: null,
      scheduledTime: null,
      scheduledSend: null,
      customList: [],
      recipients: null,
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    let formatted = value?.split("’").join("'").split("…").join("...");

    this.setState({
      [name]: formatted,
    });

    if (name == "message") {
      if (formatted?.length) {
        this.setState({
          messageLength: count(formatted),
        });
      } else {
        this.setState({
          messageLength: null,
        });
      }
    }
  }

  submit() {
    if (this.props.broadcast && !this.props.duplicate) {
      this.modify();
    } else {
      this.create();
    }
  }

  async getListCount(listName) {
    return new Promise((resolve) => {
      APIV2.getConversationBroadcastListCountForStore(
        this.props?.match?.params?.storeID,
        listName
      ).then(
        (data) => {
          resolve(data.data.count);
        },
        (e) => {
          resolve(0);
        }
      );
    });
  }

  updateListCounts(lists) {
    let promises = [];

    this.setState({
      setUpdatingCounts: true,
    });

    for (let i = 0; i < lists?.length; i++) {
      promises.push(this.getListCount(lists[i]));
    }

    Promise.all(promises).then((values) => {
      let total = 0;

      for (let i = 0; i < values?.length; i++) {
        console.log(values[i]);

        total += values[i];
      }

      this.setState({
        totalContactCount: total,
        setUpdatingCounts: false,
      });
    });
  }

  calculateSegments(message) {
    if (!message) {
      return 0;
    }

    let seg = new SegmentedMessage(StringUtils.removeLinks(message));

    return seg.segmentsCount;
  }

  handleCustomListSearchChange(e) {
    const value = e?.target?.value;

    this.setState({
      customListSearch: e?.target?.value,
      customListSearchModified: true,
    });

    if (this.state.customSearchTimeout) {
      clearTimeout(this.state.customSearchTimeout);
    }

    if (e?.target?.value) {
      this.setState({
        customSearchLoading: true,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performCustomListSearch(value);
      }, 1200);

      this.setState({
        customSearchTimeout: timeout,
      });
    }
  }

  generateListValue(customList) {
    let listName = `custom:`;

    if (!customList?.length) {
      this.setState({
        lists: [],
      });

      return;
    }

    let listMap = [];

    for (let i = 0; i < customList?.length; i++) {
      const item = customList[i];

      if (item?.type == "phone") {
        listMap.push("phone_+1" + item?.data?.phone);
      } else {
        listMap.push("Conversation__" + item?.data?._id);
      }
    }

    listName += listMap.join(",");

    console.log(listName); //debug

    this.setState({
      lists: [listName],
    });
  }

  appendToCustomList(data) {
    let customList = this.state.customList?.length ? this.state.customList : [];

    customList.push(data);

    this.generateListValue(customList);

    this.setState({
      customList,
    });
  }

  removeFromCustomList(data) {
    let customList = this.state.customList?.length ? this.state.customList : [];

    customList = _.filter(customList, (v) => {
      return v?._id != data?._id;
    });

    this.generateListValue(customList);

    this.setState({
      customList,
    });
  }

  create() {
    this.setState({ submitting: true });

    let dateTime = "now";

    if (this.state.scheduledSend != "now") {
      dateTime = moment(this.state.scheduledDate?.toISOString())
        .hours(this.state.scheduledTime.getHours())
        .minutes(this.state.scheduledTime.getMinutes())
        .seconds(0)
        .millisecond(0)
        .toDate();

      if (dateTime < moment().seconds(0).millisecond(0).toDate()) {
        this.setError("error", "The date/time selected is in the past.");

        return this.setState({ submitting: false });
      }

      dateTime = dateTime?.toISOString();
    }

    let mappedCustomList = this.state.customList?.length
      ? this.state.customList?.map((item) => {
          console.log(item); //debug

          return {
            type: item?.type,
            data: {
              _id: item?.data?._id ? item?.data?._id : null,
              recipientName: item?.data?.recipientName
                ? item?.data?.recipientName
                : null,
              recipientPhone: item?.data?.recipientPhone
                ? item?.data?.recipientPhone
                : null,

              customer: item?.data?.customer
                ? {
                    _id: item?.data?.customer?._id,
                    name: item?.data?.customer?.name,
                    phone: item?.data?.customer?.phone,
                    email: item?.data?.customer?.email,
                    storeID: item?.data?.customer?.storeID,
                  }
                : null,
              lead: item?.data?.lead
                ? {
                    _id: item?.data?.lead?._id,
                    name: item?.data?.lead?.name,
                    phone: item?.data?.lead?.phone,
                    email: item?.data?.lead?.email,
                    storeID: item?.data?.lead?.storeID,
                  }
                : null,
            },
            _id: item?._id ? item?._id : null,
          };
        })
      : [];

    APIV2.createConversationBroadcast(
      this.props?.store?._id,
      this.state.lists,
      this.state.name,
      this.state.message,
      this.state.mediaUrl ? this.state.mediaUrl : null,
      dateTime,
      mappedCustomList
    )

      .then(
        (data) => {
          const broadcast = data.data.broadcast;

          this.toggleModal();

          PubSub.publish(Event.CONVERSATION_BROADCAST.CREATED, broadcast);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create broadcast - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    let dateTime = "now";

    if (this.state.scheduledSend != "now") {
      dateTime = moment(this.state.scheduledDate?.toISOString())
        .hours(this.state.scheduledTime.getHours())
        .minutes(this.state.scheduledTime.getMinutes())
        .seconds(0)
        .millisecond(0)
        .toDate();

      if (dateTime < moment().seconds(0).millisecond(0).toDate()) {
        this.setError("error", "The date/time selected is in the past.");

        return this.setState({ submitting: false });
      }

      dateTime = dateTime?.toISOString();
    }

    if (
      this.calculateSegments(this.state.message) > 2 &&
      !this.userIsGlobal()
    ) {
      this.setError(
        "error",
        "Your message is too long. Please use 2 or less segments. Consider removing emojis to allow for more characters"
      );

      return this.setState({ submitting: false });
    }

    let mappedCustomList = this.state.customList?.length
      ? this.state.customList?.map((item) => {
          console.log(item); //debug

          return {
            type: item?.type,
            data: {
              _id: item?.data?._id ? item?.data?._id : null,
              recipientName: item?.data?.recipientName
                ? item?.data?.recipientName
                : null,
              recipientPhone: item?.data?.recipientPhone
                ? item?.data?.recipientPhone
                : null,

              customer: item?.data?.customer
                ? {
                    _id: item?.data?.customer?._id,
                    name: item?.data?.customer?.name,
                    phone: item?.data?.customer?.phone,
                    email: item?.data?.customer?.email,
                    storeID: item?.data?.customer?.storeID,
                  }
                : null,
              lead: item?.data?.lead
                ? {
                    _id: item?.data?.lead?._id,
                    name: item?.data?.lead?.name,
                    phone: item?.data?.lead?.phone,
                    email: item?.data?.lead?.email,
                    storeID: item?.data?.lead?.storeID,
                  }
                : null,
            },
            _id: item?._id ? item?._id : null,
          };
        })
      : [];

    APIV2.modifyConversationBroadcast(
      this.props?.broadcast?.storeID,
      this.props.broadcast?._id,
      this.state.lists,
      this.state.name,
      this.state.message,
      this.state.mediaUrl ? this.state.mediaUrl : null,
      dateTime,
      mappedCustomList
    )
      .then(
        (data) => {
          const broadcast = data.data.broadcast;

          this.toggleModal();

          PubSub.publish(Event.CONVERSATION_BROADCAST.MODIFIED, broadcast);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify broadcast - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  createConversation(phone, customerID = null, leadID = null) {
    return new Promise((resolve, reject) => {
      if (!StringUtils.isValidPhoneNumber(phone)) {
        console.log(phone);

        return reject("Invalid phone number");
      }

      // TODO: strip phone to just numbers

      let numbers = phone.replace(/[^\d]/g, ""); //This strips all characters that aren't digits
      if (numbers.length != 10) {
        return;
      }

      let parsedPhone = `+1${numbers}`;

      let payload = {
        unreadCount: 0,
        latestMessageID: null,
        latestMessageTimestamp: new Date(),
        customerID: customerID ? customerID : null,
        leadID: leadID ? leadID : null,
        storeID: this.props?.broadcast
          ? this.props?.broadcast?.storeID
          : this.props.store?._id?.toString(),
        status: "OPEN",
        recipientName: parsedPhone,
        recipientPhone: parsedPhone,
      };

      this.setState({
        creating: phone,
      });

      APIV2.createConversation(payload)
        .then(
          (data) => {
            console.log(data.data.conversation);

            resolve(data);
          },
          (e) => {
            reject(
              e?.response?.body?.message ??
                "Unable to create new conversation - unknown error occurred. Try again."
            );
          }
        )
        .finally(() => {
          this.setState({
            creating: false,
          });
        });
    });
  }

  userIsGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.broadcast && !this.props.duplicate
                ? "Modify Broadcast"
                : null}
              {this.props.broadcast && this.props.duplicate
                ? "Duplicate Broadcast"
                : null}
              {!this.props.broadcast ? "New Broadcast" : null}
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.lists?.length ||
                  !this.state.name ||
                  !this.state.message ||
                  !this.state.scheduledSend ||
                  (this.calculateSegments(this.state.message) > 2 &&
                    !this.userIsGlobal()) ||
                  (this.state.scheduledSend == "scheduled" &&
                    (!this.state.scheduledDate || !this.state.scheduledTime)) ||
                  (!this.state.message
                    ?.toLowerCase()
                    ?.includes("project leannation") &&
                    !this.state.message?.toLowerCase()?.includes("pln") &&
                    !this.state.message
                      ?.toLowerCase()
                      ?.includes("project lean nation"))
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>
                    {this.props.broadcast && !this.props.duplicate
                      ? "Save"
                      : "Add"}
                  </>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>
                Recipients{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                block
                placement="auto"
                value={this.state.recipients}
                placeholder="Choose An Option"
                data={[
                  {
                    value: "list",
                    label: (
                      <>
                        <p
                          className="m-0 text-dark"
                          style={{ lineHeight: 1.3, fontSize: 15 }}
                        >
                          Built-In List
                        </p>
                        <div className="text-muted" style={{ fontSize: 12 }}>
                          Choose from smart lists built into Storefront
                        </div>
                      </>
                    ),
                  },
                  {
                    value: "custom",
                    label: (
                      <>
                        <p
                          className="m-0 text-dark"
                          style={{ lineHeight: 1.3, fontSize: 15 }}
                        >
                          Custom List
                        </p>
                        <div className="text-muted" style={{ fontSize: 12 }}>
                          Create a custom list for this broadcast
                        </div>
                      </>
                    ),
                  },
                ]}
                onChange={(v) => {
                  this.setState({
                    recipients: v,
                    lists: [],
                  });

                  if (v == "list") {
                    this.setState({
                      customList: [],
                    });
                  }
                }}
                cleanable={false}
                searchable={false}
              ></SelectPicker>
              {this.state.recipients == "list" ? (
                <CheckPicker
                  className="mt-2"
                  data={this.state.listOptions}
                  value={this.state.lists}
                  onChange={(v) => {
                    let disabledOptions = [];

                    this.updateListCounts(v);

                    if (v?.includes("dynamic:all_actives")) {
                      v = _.filter(v, (opt) => {
                        return (
                          opt != "dynamic:all_actives_this_week" &&
                          opt != "dynamic:delivery_actives_this_week" &&
                          opt != "dynamic:pickup_actives_this_week"
                        );
                      });

                      disabledOptions.push("dynamic:all_actives_this_week");
                      disabledOptions.push(
                        "dynamic:delivery_actives_this_week"
                      );
                      disabledOptions.push("dynamic:pickup_actives_this_week");
                    }

                    if (v?.includes("dynamic:all_actives_this_week")) {
                      v = _.filter(v, (opt) => {
                        return (
                          opt != "dynamic:delivery_actives_this_week" &&
                          opt != "dynamic:pickup_actives_this_week"
                        );
                      });

                      disabledOptions.push(
                        "dynamic:delivery_actives_this_week"
                      );
                      disabledOptions.push("dynamic:pickup_actives_this_week");
                    }

                    this.setState({ lists: v, disabledOptions });
                  }}
                  placement="auto"
                  block
                  cleanable={true}
                  searchable={true}
                  groupBy="group"
                  disabledItemValues={this.state.disabledListOptions}
                ></CheckPicker>
              ) : null}
              {this.state.lists?.length && this.state.recipients == "list" ? (
                <div>
                  <p className="m-0 text-muted" style={{ fontSize: 12 }}>
                    {this.state.updateListCounts
                      ? "Estimating contact count..."
                      : `Est. ${StringUtils.numberFormat(
                          this.state.totalContactCount
                        )} contacts`}
                  </p>
                </div>
              ) : null}
              {this.state.recipients == "custom" ? (
                <>
                  <div className="mt-2">
                    <div style={{ position: "relative" }}>
                      <Whisper
                        placement="autoVerticalStart"
                        trigger={["focus", "click"]}
                        ref={this.searchRef}
                        enterable
                        speaker={
                          <Popover
                            className="p-0 dropdown-popover"
                            style={{
                              width: "calc(100% - 3rem)",
                              maxWidth: "calc(600px - 3rem)",
                            }}
                          >
                            <div>
                              {this.state.searchLoading ||
                              !this.state.customListSearch ||
                              this.state.searchResults?.members?.length ||
                              this.state.searchResults?.leads?.length ? (
                                <div className="border-bottom p-2">
                                  <h4 className="mb-0">Contacts</h4>
                                  <div className="mb-0">
                                    {!this.state.customListSearch ? (
                                      <>
                                        Search leads and members or add a phone
                                        number
                                      </>
                                    ) : null}
                                    {this.state.customListSearch &&
                                    this.state.searchLoading ? (
                                      <>Searching contacts...</>
                                    ) : null}
                                    {this.state.customListSearch &&
                                    !this.state.searchLoading &&
                                    !this.state.searchResults?.members
                                      ?.length &&
                                    !this.state.searchResults?.leads?.length ? (
                                      <>No contacts matching your search.</>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                              <div
                                className=""
                                style={{
                                  maxHeight: "calc(100vh - 300px)",
                                  overflowY: "scroll",
                                  position: "relative",
                                }}
                              >
                                {!this.state.customSearchLoading &&
                                !this.state.customSearchResults?.members
                                  ?.length &&
                                !this.state.customSearchResults?.leads
                                  ?.length &&
                                this.state.customListSearch ? (
                                  <>
                                    <div className="p-2 border-bottom bg-white">
                                      <h4 className="text-dark m-0">
                                        Add Phone Number To Broadcast
                                      </h4>
                                      {!StringUtils.isValidPhoneNumber(
                                        this.state.customListSearch
                                      ) ? (
                                        <p
                                          className="m-0 text-muted"
                                          style={{ fontSize: 12 }}
                                        >
                                          Enter a valid phone number to add it
                                          to the broadcast
                                        </p>
                                      ) : (
                                        <p
                                          className="m-0 text-muted"
                                          style={{ fontSize: 12 }}
                                        >
                                          Add{" "}
                                          {StringUtils.formatPhoneNumber(
                                            this.state.customListSearch
                                          )}{" "}
                                          to the broadcast
                                        </p>
                                      )}
                                    </div>
                                    <div
                                      className={`cursor-pointer ${
                                        StringUtils.isValidPhoneNumber(
                                          this.state.customListSearch
                                        ) && "bg-superlight--hover"
                                      } px-2 py-1`}
                                      style={{ height: 40 }}
                                      onClick={() => {
                                        if (
                                          !StringUtils.isValidPhoneNumber(
                                            this.state.customListSearch
                                          )
                                        ) {
                                          return;
                                        }

                                        let data = {
                                          type: "phone",
                                          data: {
                                            phone: this.state.customListSearch,
                                          },
                                          _id: StringUtils.uuid(),
                                        };

                                        this.searchRef.current.close();

                                        this.setState({
                                          customSearchResults: {
                                            members: [],
                                            leads: [],
                                          },
                                        });

                                        this.appendToCustomList(data);

                                        this.setState({
                                          customListSearch: "",
                                        });
                                      }}
                                    >
                                      <Row className="align-items-center">
                                        <Col xs="">
                                          <h4
                                            className="m-0 text-dark"
                                            style={{
                                              paddingTop:
                                                StringUtils.isValidPhoneNumber(
                                                  this.state.customListSearch
                                                )
                                                  ? ""
                                                  : "0.4rem",
                                            }}
                                          >
                                            {StringUtils.isValidPhoneNumber(
                                              this.state.customListSearch
                                            )
                                              ? StringUtils.formatPhoneNumber(
                                                  this.state.customListSearch
                                                )
                                              : this.state.customListSearch}
                                          </h4>
                                        </Col>
                                        <Col xs="auto">
                                          {StringUtils.isValidPhoneNumber(
                                            this.state.customListSearch
                                          ) ? (
                                            <Button
                                              size="sm"
                                              outline
                                              color="dark"
                                              className="btn-icon-only border-0"
                                            >
                                              <i className="mdi mdi-chevron-right"></i>
                                            </Button>
                                          ) : null}
                                        </Col>
                                      </Row>
                                    </div>
                                    {this.state.customError ? (
                                      <div className={`px-2 py-1`}>
                                        <p
                                          className="m-0 text-danger"
                                          style={{ fontSize: 12 }}
                                        >
                                          {this.state.customError}
                                        </p>
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                                {!this.state.customSearchLoading &&
                                this.state.customSearchResults?.members
                                  ?.length ? (
                                  <>
                                    <div
                                      className="p-2 border-bottom bg-white"
                                      style={{
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 1,
                                      }}
                                    >
                                      <h4 className="text-dark m-0">Members</h4>
                                    </div>
                                    {this.state.customSearchResults?.members?.map(
                                      (c, i) => (
                                        <>
                                          <div
                                            className="cursor-pointer bg-superlight--hover px-2 py-1"
                                            key={c?._id}
                                            onClick={() => {
                                              if (this.state.creating) {
                                                return;
                                              }

                                              if (c?.newConversation) {
                                                return this.createConversation(
                                                  c?.customer?.phone?.replace(
                                                    "+1",
                                                    ""
                                                  ),
                                                  c?.customerID
                                                ).then((resp) => {
                                                  let data = {
                                                    type: "Customer",
                                                    data: resp?.data
                                                      ?.conversation,
                                                    _id: resp?.data
                                                      ?.conversation?._id,
                                                  };

                                                  this.appendToCustomList(data);

                                                  this.searchRef.current.close();

                                                  this.setState({
                                                    customSearchResults: {
                                                      members: [],
                                                      leads: [],
                                                    },
                                                  });

                                                  this.setState({
                                                    customListSearch: "",
                                                  });
                                                });
                                              }

                                              let data = {
                                                type: "Customer",
                                                data: c,
                                                _id: c?._id,
                                              };

                                              console.log(data);

                                              this.appendToCustomList(data);

                                              this.searchRef.current.close();

                                              this.setState({
                                                customSearchResults: {
                                                  members: [],
                                                  leads: [],
                                                },
                                              });

                                              this.setState({
                                                customListSearch: "",
                                              });
                                            }}
                                          >
                                            <Row className="align-items-center">
                                              <Col xs="">
                                                <h4 className="m-0 text-dark">
                                                  {c?.customer?.name}
                                                </h4>
                                                <p
                                                  className="m-0 text-muted small"
                                                  style={{
                                                    lineHeight: 1.2,
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                  }}
                                                >
                                                  {StringUtils.formatPhoneNumber(
                                                    c?.customer?.phone
                                                  )}
                                                </p>
                                              </Col>
                                              <Col xs="auto">
                                                <Button
                                                  size="sm"
                                                  outline
                                                  color="dark"
                                                  className="btn-icon-only border-0"
                                                  disabled={this.state.creating}
                                                >
                                                  {this.state.creating ==
                                                  c?.customer?.phone ? (
                                                    <Spinner size="sm" />
                                                  ) : (
                                                    <i className="mdi mdi-chevron-right"></i>
                                                  )}
                                                </Button>
                                              </Col>
                                            </Row>
                                          </div>
                                          {i !=
                                          this.state.customSearchResults
                                            ?.members?.length -
                                            1 ? (
                                            <hr className="m-0"></hr>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </>
                                ) : null}
                                {!this.state.customSearchLoading &&
                                this.state.customSearchResults?.leads
                                  ?.length ? (
                                  <>
                                    <div
                                      className={`p-2 border-bottom bg-white ${
                                        this.state.customSearchResults?.members
                                          ?.length && "mt-3"
                                      }`}
                                      style={{
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 1,
                                      }}
                                    >
                                      <h4 className={`text-dark m-0`}>Leads</h4>
                                    </div>
                                    {this.state.customSearchResults?.leads?.map(
                                      (c, i) => (
                                        <>
                                          <div
                                            className="cursor-pointer bg-superlight--hover px-2 py-1"
                                            key={c?._id}
                                            onClick={() => {
                                              if (this.state.creating) {
                                                return;
                                              }

                                              if (c?.newConversation) {
                                                return this.createConversation(
                                                  c?.lead?.phone?.replace(
                                                    "+1",
                                                    ""
                                                  ),
                                                  null,
                                                  c?.leadID
                                                ).then((resp) => {
                                                  let data = {
                                                    type: "CustomerLead",
                                                    data: resp?.data
                                                      ?.conversation,
                                                    _id: resp?.data
                                                      ?.conversation?._id,
                                                  };

                                                  this.appendToCustomList(data);

                                                  this.searchRef.current.close();

                                                  this.setState({
                                                    customSearchResults: {
                                                      members: [],
                                                      leads: [],
                                                    },
                                                  });

                                                  this.setState({
                                                    customListSearch: "",
                                                  });
                                                });
                                              }

                                              let data = {
                                                type: "CustomerLead",
                                                data: c,
                                                _id: c?._id,
                                              };

                                              this.appendToCustomList(data);

                                              this.searchRef.current.close();

                                              this.setState({
                                                customSearchResults: {
                                                  members: [],
                                                  leads: [],
                                                },
                                              });

                                              this.setState({
                                                customListSearch: "",
                                              });
                                            }}
                                          >
                                            <Row className="align-items-center">
                                              <Col xs="">
                                                <h4 className="m-0 text-dark">
                                                  {c?.lead?.name
                                                    ? c?.lead?.name
                                                    : StringUtils.formatPhoneNumber(
                                                        c?.lead?.phone
                                                          ? c?.lead?.phone
                                                          : c?.recipientPhone
                                                      )}
                                                </h4>
                                                <p
                                                  className="m-0 text-muted small"
                                                  style={{
                                                    lineHeight: 1.2,
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                  }}
                                                >
                                                  {c?.lead?.name
                                                    ? StringUtils.formatPhoneNumber(
                                                        c?.lead?.phone
                                                      )
                                                    : c?.lead?.email
                                                    ? c?.lead?.email
                                                    : "Lead"}
                                                </p>
                                              </Col>
                                              <Col xs="auto">
                                                <Button
                                                  size="sm"
                                                  outline
                                                  color="dark"
                                                  className="btn-icon-only border-0"
                                                  disabled={this.state.creating}
                                                >
                                                  {this.state.creating ==
                                                  c?.lead?.phone ? (
                                                    <Spinner size="sm" />
                                                  ) : (
                                                    <i className="mdi mdi-chevron-right"></i>
                                                  )}
                                                </Button>
                                              </Col>
                                            </Row>
                                          </div>
                                          {i !=
                                          this.customSearchResults?.members
                                            ?.length -
                                            1 ? (
                                            <hr className="m-0"></hr>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </>
                                ) : null}
                                {this.state.customSearchLoading ? (
                                  <>
                                    <div className="text-center py-4">
                                      <Spinner size="sm" color="dark"></Spinner>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </Popover>
                        }
                      >
                        <div>
                          <InputGroup>
                            <InputGroupAddon
                              size="small"
                              addonType="prepend"
                              style={{ height: "38px" }}
                              className="new-conversation-to"
                            >
                              To:
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder="Recipient"
                              className="text-dark"
                              name="customListSearch"
                              value={this.state.customListSearch}
                              onChange={(e) => {
                                this.handleCustomListSearchChange(e);
                              }}
                              style={{ height: 38 }}
                            ></Input>
                          </InputGroup>
                        </div>
                      </Whisper>
                    </div>
                  </div>
                  {this.state.customList?.length ? (
                    <>
                      {this.state.customList?.map((v) => (
                        <div key={v?._id} className="p-2 border rounded my-2">
                          <Row className="align-items-center">
                            <Col xs="">
                              {v?.type == "phone" ? (
                                <h4
                                  className="m-0 text-dark"
                                  style={{
                                    paddingTop: StringUtils.isValidPhoneNumber(
                                      v?.data?.phone
                                    )
                                      ? ""
                                      : "0.4rem",
                                  }}
                                >
                                  {StringUtils.isValidPhoneNumber(
                                    v?.data?.phone
                                  )
                                    ? StringUtils.formatPhoneNumber(
                                        v?.data?.phone
                                      )
                                    : v?.data?.phone}
                                </h4>
                              ) : (
                                <>
                                  {v?.type == "Customer" ? (
                                    <>
                                      <h4 className="m-0 text-dark">
                                        {v?.data?.customer?.name}
                                      </h4>
                                      <p
                                        className="m-0 text-muted small"
                                        style={{
                                          lineHeight: 1.2,
                                          fontSize: 12,
                                          fontWeight: 500,
                                        }}
                                      >
                                        Member:{" "}
                                        {StringUtils.formatPhoneNumber(
                                          v?.data?.customer?.phone
                                        )}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <h4 className="m-0 text-dark">
                                        {v?.data?.lead?.name
                                          ? v?.data?.lead?.name
                                          : StringUtils.formatPhoneNumber(
                                              v?.data?.lead?.phone
                                                ? v?.data?.lead?.phone
                                                : v?.data?.recipientPhone
                                            )}
                                      </h4>
                                      <p
                                        className="m-0 text-muted small"
                                        style={{
                                          lineHeight: 1.2,
                                          fontSize: 12,
                                          fontWeight: 500,
                                        }}
                                      >
                                        {v?.data?.lead?.name
                                          ? StringUtils.formatPhoneNumber(
                                              v?.data?.lead?.phone
                                                ? v?.data?.lead?.phone
                                                : v?.data?.recipientPhone
                                            )
                                          : v?.data?.lead?.email
                                          ? v?.data?.lead?.email
                                          : "Lead"}
                                      </p>
                                    </>
                                  )}
                                </>
                              )}
                            </Col>
                            <Col xs="auto">
                              <Button
                                size="sm"
                                outline
                                color="danger"
                                className="btn-icon-only border-0"
                                onClick={() => {
                                  this.removeFromCustomList(v);
                                }}
                              >
                                <i className="mdi mdi-close"></i>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </>
                  ) : null}
                </>
              ) : null}
            </FormGroup>
            <FormGroup>
              <h5>
                Name{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>

            <FormGroup>
              <h5>
                Message{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              {this.state.message &&
              !this.state.message
                ?.toLowerCase()
                ?.includes("project leannation") &&
              !this.state.message?.toLowerCase()?.includes("pln") &&
              !this.state.message
                ?.toLowerCase()
                ?.includes("project lean nation") ? (
                <>
                  <p
                    className="mb-2 text-danger small"
                    style={{ lineHeight: 1.2 }}
                  >
                    Your message must include "Project LeanNation" or "PLN"
                  </p>
                </>
              ) : null}
              {this.calculateSegments(this.state.message) > 2 &&
              !this.userIsGlobal() ? (
                <div
                  className="text-danger my-2 small"
                  style={{ lineHeight: 1.2 }}
                >
                  Your message is to long. Please shorten your broadcast message
                  to 2 or less segments. Consider removing any emojis & special
                  characters to allow for more characters.
                </div>
              ) : null}
              <div className="">
                <textarea
                  className="form-control"
                  type="textarea"
                  id="message-textarea"
                  placeholder="Type something..."
                  ref={(c) => {
                    if (!this.state.textareaRef) {
                      this.setState({ textareaRef: c }, () => {
                        autosize(this.state.textareaRef);
                      });
                    }
                  }}
                  value={this.state.message}
                  style={{
                    minHeight: 82,
                    maxHeight: 248,
                    resize: "none",
                  }}
                  rows={1}
                  name="message"
                  onChange={this.handleInputChange.bind(this)}
                  required={true}
                ></textarea>

                {this.state.mediaUrl ? (
                  <>
                    <div
                      className="p-2 border rounded bg-white mt-2"
                      style={{ fontSize: 12 }}
                    >
                      Attached:{" "}
                      <a
                        href={this.state.mediaUrl}
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();

                          LinkUtils.openInNewTab(this.state.mediaUrl);
                        }}
                      >
                        {StringUtils.getAttachmentFileName(this.state.mediaUrl)}
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="p-2 rounded border mt-2 border-lighter">
                <Row className="align-items-center">
                  <Col xs="">
                    <Whisper
                      trigger={["click"]}
                      ref={this.emojiRef}
                      onClose={() => {
                        this.setState({
                          emojiOpen: false,
                        });
                      }}
                      placement={"autoVerticalStart"}
                      preventOverflow={true}
                      speaker={
                        <Popover
                          full
                          className="dropdown-popover quick-reply"
                          style={{}}
                        >
                          <EmojiPicker
                            emojiStyle="apple"
                            defaultSkinTone="neutral"
                            skinTonesDisabled={true}
                            theme="light"
                            getEmojiUrl={(data, e) => {
                              return `/emojis/apple/64/${data}.png`;
                            }}
                            onEmojiClick={(v) => {
                              let b = this.state.message;

                              b = b + v?.emoji;

                              if (b?.length) {
                                this.setState({
                                  messageLength: count(b),
                                });
                              } else {
                                this.setState({
                                  messageLength: null,
                                });
                              }

                              this.setState({ message: b });

                              this.emojiRef.current.close();
                            }}
                          ></EmojiPicker>
                        </Popover>
                      }
                    >
                      <Button
                        size="sm"
                        outline
                        color="dark"
                        className="btn-icon-only border-0 mr-0"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();

                          if (this.state.emojiOpen) {
                            return this.setState({ emojiOpen: false });
                          }

                          this.setState({ emojiOpen: true });
                        }}
                      >
                        <i className="mdi mdi-emoticon-outline"></i>
                      </Button>
                    </Whisper>
                    <Whisper
                      trigger={["click"]}
                      ref={this.quickReplyRef}
                      onClose={() => {
                        this.setState({
                          quickReplyOpen: false,
                          quickReplyFilter: null,
                          quickReplySearchValue: null,
                          quickReplySearch: false,
                        });
                      }}
                      placement={"autoVerticalStart"}
                      preventOverflow={true}
                      speaker={
                        <Popover
                          full
                          className="dropdown-popover quick-reply"
                          style={{
                            width: "calc(100% - 2rem)",
                            maxWidth: 650,
                          }}
                        >
                          <div className="p-3 border-bottom">
                            <Row className="align-items-center">
                              <Col xs="auto" className="pr-0">
                                <i
                                  className="mdi mdi-inbox-arrow-up text-dark"
                                  style={{
                                    fontSize: 22,
                                    lineHeight: 1,
                                    position: "relative",
                                    top: 1,
                                  }}
                                ></i>
                              </Col>
                              <Col xs="" className="pl-2">
                                {this.state.quickReplySearch ? (
                                  <Input
                                    bsSize="sm"
                                    placeholder="Search Templates..."
                                    onChange={this.handleInputChange.bind(this)}
                                    value={this.state.quickReplySearchValue}
                                    name="quickReplySearchValue"
                                  ></Input>
                                ) : (
                                  <h4 className="m-0 text-dark">
                                    Saved Replies
                                  </h4>
                                )}
                              </Col>
                              <Col xs="auto" className="pl-0">
                                <Button
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    if (this.state.quickReplySearch) {
                                      this.setState({
                                        quickReplySearch: false,
                                        quickReplySearchValue: "",
                                        quickReplyFilter: null,
                                      });

                                      this.loadTemplates(
                                        this.props.store?._id,
                                        1,
                                        10,
                                        null,
                                        { name: -1 }
                                      );

                                      return;
                                    }

                                    this.setState({
                                      quickReplySearch: true,
                                    });
                                  }}
                                  className="btn-icon-only border-0 mr-0"
                                >
                                  {this.state.quickReplySearch ? (
                                    <i className="mdi mdi-cancel"></i>
                                  ) : (
                                    <i className="mdi mdi-magnify"></i>
                                  )}
                                </Button>
                                <Button
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    this.quickReplyRef.current.close();
                                  }}
                                  className="btn-icon-only border-0"
                                >
                                  <i className="mdi mdi-close"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          <div
                            style={{
                              minHeight: 200,
                              maxHeight: 300,
                              overflowX: "hidden",
                              overflowY: "auto",
                              position: "relative",
                            }}
                          >
                            {this.state.loadingTemplates ? (
                              <>
                                <div
                                  className="p-3"
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                >
                                  <div
                                    className="text-center"
                                    style={{
                                      position: "relative",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                    }}
                                  >
                                    <Spinner></Spinner>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {this.state.templates?.length ? (
                                  <>
                                    {this.state.templates?.map((t, i) => (
                                      <div
                                        className={`p-3 bg-superlight--hover cursor-pointer ${
                                          i != this.state.templates?.length &&
                                          "border-bottom"
                                        }`}
                                        onClick={() => {
                                          this.quickReplyRef.current.close();

                                          if (t.message?.length) {
                                            this.setState({
                                              messageLength: count(t.message),
                                            });
                                          } else {
                                            this.setState({
                                              messageLength: null,
                                            });
                                          }

                                          this.setState({
                                            message: t.message,
                                          });

                                          setTimeout(() => {
                                            var len = t.message.length;

                                            this.state.textareaRef.focus();

                                            this.state.textareaRef.selectionEnd =
                                              len;

                                            autosize.update(
                                              this.state.textareaRef
                                            );
                                          }, 0);
                                        }}
                                      >
                                        <Row className="align-items-center">
                                          <Col xs="12" sm="">
                                            <p
                                              className="mb-0 text-muted font-weight-500 text-truncate"
                                              style={{ fontSize: 12 }}
                                            >
                                              {!t.storeID &&
                                                "Built-In Template: "}
                                              {t.name}
                                            </p>
                                            <p
                                              className="m-0 text-dark"
                                              dangerouslySetInnerHTML={{
                                                __html: t?.message?.replace(
                                                  /\n/g,
                                                  "<br />"
                                                ),
                                              }}
                                            ></p>
                                          </Col>
                                          <Col xs="12" sm="auto">
                                            <div className="d-block d-sm-none mt-3">
                                              <Button
                                                size="sm"
                                                outline
                                                block
                                                color="dark"
                                              >
                                                <i
                                                  className="mdi mdi-plus"
                                                  style={{ fontSize: 16 }}
                                                ></i>
                                              </Button>
                                            </div>
                                            <div className="d-none d-sm-block">
                                              <Button
                                                size="sm"
                                                outline
                                                color="dark"
                                                className="btn-icon-only border-0"
                                              >
                                                <i className="mdi mdi-chevron-right"></i>
                                              </Button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="p-3 text-center"
                                      style={{
                                        position: "relative",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                      }}
                                    >
                                      No conversation templates available.
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="p-3 border-top">
                            <Row className="align-items-center">
                              <Col xs="">
                                {this.state.loadingTemplates ? (
                                  <p
                                    style={{ fontSize: 14 }}
                                    className="m-0 text-dark"
                                  >
                                    Loading Templates...
                                  </p>
                                ) : (
                                  <p
                                    style={{ fontSize: 14 }}
                                    className="m-0 text-dark"
                                  >
                                    {this.state.results?.start} -{" "}
                                    {this.state.results?.end} of{" "}
                                    {this.state.results.total}
                                  </p>
                                )}
                              </Col>
                              <Col xs="auto">
                                <Button
                                  className="btn-icon-only border-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  disabled={!this.state.pagination?.hasPrevious}
                                  onClick={() => {
                                    this.loadTemplates(
                                      this.props.store?._id,
                                      this.state.pagination?.previous,
                                      10,
                                      this.state.quickReplyFilter,
                                      {
                                        name: -1,
                                      }
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-chevron-left"></i>
                                </Button>
                                <Button
                                  className="btn-icon-only border-0"
                                  size="sm"
                                  outline
                                  color="dark"
                                  disabled={!this.state.pagination?.hasNext}
                                  onClick={() => {
                                    this.loadTemplates(
                                      this.props.store?._id,
                                      this.state.pagination?.next,
                                      10,
                                      this.state.quickReplyFilter,
                                      {
                                        name: -1,
                                      }
                                    );
                                  }}
                                >
                                  <i className="mdi mdi-chevron-right"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Popover>
                      }
                    >
                      <Button
                        size="sm"
                        outline
                        color="dark"
                        className="btn-icon-only border-0"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();

                          if (this.state.quickReplyOpen) {
                            return this.setState({
                              quickReplyOpen: false,
                            });
                          }

                          this.loadTemplates(
                            this.props.store?._id,
                            1,
                            10,
                            null,
                            { name: -1 }
                          );

                          return this.setState({
                            quickReplyOpen: true,
                          });
                        }}
                      >
                        <i className="mdi mdi-inbox-arrow-up"></i>
                      </Button>
                    </Whisper>
                    <Whisper
                      trigger={["click"]}
                      ref={this.quickReplyRef}
                      onClose={() => {
                        this.setState({
                          quickReplyOpen: false,
                          quickReplyFilter: null,
                          quickReplySearchValue: null,
                          quickReplySearch: false,
                        });
                      }}
                      placement={"autoVerticalStart"}
                      preventOverflow={true}
                      speaker={
                        <Popover
                          full
                          className="dropdown-popover quick-reply"
                          style={{
                            width: "calc(100% - 2rem)",
                            maxWidth: 650,
                          }}
                        >
                          <div className="p-3 border-bottom">
                            <Row className="align-items-center">
                              <Col xs="auto" className="pr-0">
                                <i
                                  className="mdi mdi-link-plus text-dark"
                                  style={{
                                    fontSize: 22,
                                    lineHeight: 1,
                                    position: "relative",
                                    top: 1,
                                  }}
                                ></i>
                              </Col>
                              <Col xs="" className="pl-2">
                                <h4 className="m-0 text-dark">Quick Links</h4>
                              </Col>
                              <Col xs="auto" className="pl-0">
                                <Button
                                  size="sm"
                                  outline
                                  color="dark"
                                  onClick={() => {
                                    this.quickReplyRef.current.close();
                                  }}
                                  className="btn-icon-only border-0"
                                >
                                  <i className="mdi mdi-close"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          <div
                            style={{
                              minHeight: 200,
                              maxHeight: 300,
                              overflowX: "hidden",
                              overflowY: "auto",
                              position: "relative",
                            }}
                          >
                            {this.state.quickLinks?.length ? (
                              <>
                                {this.state.quickLinks?.map((t, i) => (
                                  <div
                                    className={`p-3 bg-superlight--hover cursor-pointer ${
                                      i != this.state.quickLinks?.length - 1 &&
                                      "border-bottom"
                                    }`}
                                    onClick={() => {
                                      this.quickReplyRef.current.close();

                                      const mess =
                                        this.state.message +
                                        `${
                                          this.state.message?.length > 0
                                            ? " "
                                            : ""
                                        }${t.url}`;

                                      if (mess?.length) {
                                        this.setState({
                                          messageLength: count(mess),
                                        });
                                      } else {
                                        this.setState({
                                          messageLength: null,
                                        });
                                      }

                                      this.setState({
                                        message: mess,
                                      });

                                      setTimeout(() => {
                                        var len = mess.length;

                                        this.state.textareaRef.focus();

                                        this.state.textareaRef.selectionEnd =
                                          len;

                                        autosize.update(this.state.textareaRef);
                                      }, 0);
                                    }}
                                  >
                                    <Row className="align-items-center">
                                      <Col
                                        xs="12"
                                        sm=""
                                        style={{
                                          maxWidth: "calc(100% - 62px)",
                                        }}
                                      >
                                        <p
                                          className="mb-0 text-dark font-weight-500 text-truncate"
                                          style={{ fontSize: 16 }}
                                        >
                                          {t.name}
                                        </p>
                                        <p
                                          className="m-0 text-muted text-truncate"
                                          style={{ fontSize: 12 }}
                                        >
                                          {t.url}
                                        </p>
                                      </Col>
                                      <Col xs="12" sm="auto">
                                        <div className="d-block d-sm-none mt-3">
                                          <Button
                                            size="sm"
                                            outline
                                            block
                                            color="dark"
                                          >
                                            <i
                                              className="mdi mdi-plus"
                                              style={{ fontSize: 16 }}
                                            ></i>
                                          </Button>
                                        </div>
                                        <div className="d-none d-sm-block">
                                          <Button
                                            size="sm"
                                            outline
                                            color="dark"
                                            className="btn-icon-only border-0"
                                          >
                                            <i className="mdi mdi-plus"></i>
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                <div
                                  className="p-3 text-center"
                                  style={{
                                    position: "relative",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  }}
                                >
                                  No quick links available.
                                </div>
                              </>
                            )}
                          </div>
                        </Popover>
                      }
                    >
                      <Button
                        size="sm"
                        outline
                        color="dark"
                        className="btn-icon-only border-0"
                        type="button"
                      >
                        <i className="mdi mdi-link-plus"></i>
                      </Button>
                    </Whisper>
                    <Whisper
                      trigger="hover"
                      placement="auto"
                      speaker={
                        <Tooltip>Attach Contact Card To Message</Tooltip>
                      }
                      delayOpen={400}
                    >
                      <Button
                        size="sm"
                        outline={
                          this.state.mediaUrl != this.props.store?.vcardURL
                        }
                        color="dark"
                        className="btn-icon-only border-0 ml-0"
                        type="button"
                        disabled={!this.props.store?.vcardURL}
                        onClick={(e) => {
                          if (
                            this.state.mediaUrl == this.props.store?.vcardURL
                          ) {
                            return this.setState({
                              mediaUrl: "",
                            });
                          }

                          this.setState({
                            mediaUrl: this.props.store?.vcardURL,
                          });
                        }}
                      >
                        <i className="mdi mdi-card-account-phone-outline"></i>
                      </Button>
                    </Whisper>
                    {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-image"></i>
                    </Button>*/}

                    {/*<Button
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only border-0"
                        >
                          <i className="mdi mdi-note-plus-outline"></i>
                </Button>*/}
                  </Col>
                  <Col xs="auto">
                    <div style={{ fontSize: 12 }}>
                      {this.state.messageLength ? (
                        <>
                          Chars:{" "}
                          {StringUtils.numberFormat(
                            this.state.messageLength?.length
                          )}{" "}
                          ({this.calculateSegments(this.state.message)} segments
                          - Cost: ~
                          {this.estimateMessageCost(this.state.messageLength)}
                          /sms)
                        </>
                      ) : (
                        <>Chars: 0</>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </FormGroup>
            <FormGroup>
              <h5>
                Schedule{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                block
                placement="auto"
                value={this.state.scheduledSend}
                placeholder="Choose An Option"
                data={[
                  {
                    value: "now",
                    label: "Send Immediately",
                  },
                  {
                    value: "scheduled",
                    label: "Specify A Date & Time",
                  },
                ]}
                onChange={(v) => {
                  this.setState({
                    scheduledSend: v,
                  });
                }}
                cleanable={false}
                searchable={false}
              ></SelectPicker>
            </FormGroup>
            {this.state.scheduledSend == "scheduled" ? (
              <>
                <FormGroup>
                  <h5>
                    Date{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <DatePicker
                    block
                    placement="auto"
                    value={this.state.scheduledDate}
                    onChange={(v) => {
                      this.setState({
                        scheduledDate: v,
                      });
                    }}
                    disabledDate={(date) => {
                      return (
                        date <
                        moment()
                          .hours(0)
                          .minutes(0)
                          .seconds(0)
                          .millisecond(0)
                          .toDate()
                      );
                    }}
                    placeholder="MM/DD/YYYY"
                    format="MM/dd/yyyy"
                    oneTap
                  ></DatePicker>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Time{" "}
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <DatePicker
                    block
                    placement="auto"
                    value={this.state.scheduledTime}
                    onChange={(v) => {
                      this.setState({
                        scheduledTime: v,
                      });
                    }}
                    placeholder="HH:MM"
                    format="hh:mm a"
                    showMeridian={true}
                  ></DatePicker>
                  <div>
                    <p className="m-0 text-muted" style={{ fontSize: 12 }}>
                      Timezone: {this.props.store?.timezone}
                    </p>
                  </div>
                </FormGroup>
              </>
            ) : null}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(ConversationBroadcastDrawer);
