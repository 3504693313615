/**
 * Event constants for the app.
 */

class Constant {
  static STRIPE_KEY_TEST =
    "pk_test_51JLvqwHigOZnL8zU7ohNYK6rLGKYjVwqPXIYLjRwPqcxaEPZlSJWZoz51pQdlRaLnH0Rf2dUBNkQSILiGHSyKBK200IjgYgx3A";
  static STRIPE_KEY =
    "pk_live_51JLvqwHigOZnL8zUzZahCuNfPFUA54fwhEyqDEN74eJEaT8JTVqgw63QRba6BKMA5KGSOO1806M7id8ckIZWDTPw00IQq5XaHH";

  static IOS_APP_URL =
    "https://apps.apple.com/us/app/pln-storefront/id1598920273";

  static ENV_HOST = {
    DEV: "localhost",
    STAGING: "admin-staging.projectleannation.com",
    PRODUCTION: "admin.projectleannation.com",
  };

  static CACHE = {
    TOKEN: "cache_token",
    USER: "cache_user",
  };

  static TONES = {
    MESSAGE_NOTIFICATION:
      "https://s3.amazonaws.com/cdn.projectleannation.com/assets/ringtone-1-46486.mp3",
  };

  static HEATING_INSTRUCTIONS = {
    MICROWAVE: `<p><strong>From The Freezer: </strong>Heat in the Microwave for 3-4 minutes until desired temperature. Allow all steam to dissipate before opening the seal. Remove the seal to open container and stir contents. Heat for another 30-45 seconds.</p>
    <p><strong>Thawed / From The Fridge: </strong>Heat in the Microwave for 2-3 minutes until desired temperature. Allow all steam to dissipate before opening the seal. Remove the seal to open container and stir contents. Heat for another 15-30 seconds.</p>`,
    AIR_FRY: `<p><span style="color: rgb(82,95,127);background-color: rgb(247,247,246);font-size: 16px;font-family: Karla, sans-serif;"><strong>From The Freezer: </strong>Transfer meal to oven-save dish. Heat for 8-10 minutes or until desired temperature. Stir contents &amp; enjoy your meal!</span></p>
     <p><span style="color: rgb(82,95,127);background-color: rgb(247,247,246);font-size: 16px;font-family: Karla, sans-serif;"><strong>Thawed / From The Fridge:</strong> Transfer meal to oven-save dish. Heat for 7-8 minutes or until desired temperature. Stir contents &amp; enjoy your meal!</span></p>`,
    OVEN: `<p><strong>From The Freezer: </strong>Transfer meal to oven-save dish. Heat for 12-15 minutes at 300°F or until desired temperature. Stir contents &amp; enjoy your meal!</p>
    <p><strong>Thawed / From The Fridge: </strong>Transfer meal to oven-save dish. Heat for 8-12 minutes at 300°F or until desired temperature. Stir contents &amp; enjoy your meal!</p>`,
  };

  static AFFILIATE = {
    TYPES: [
      {
        value: "Fitness",
        label: "Fitness",
      },
      {
        value: "Spa",
        label: "Spa",
      },

      {
        value: "Medical Facility",
        label: "Medical Facility",
      },
      {
        value: "First Responder - Fire",
        label: "First Responder - Fire",
      },
      {
        value: "First Responder - Police",
        label: "First Responder - Police",
      },
      {
        value: "First Responder - EMS",
        label: "First Responder - EMS",
      },
      {
        value: "Elder Care",
        label: "Elder Care",
      },
      {
        value: "School",
        label: "School",
      },
      {
        value: "College / University",
        label: "College / University",
      },
      {
        value: "Corporate",
        label: "Corporate",
      },
      {
        value: "Individual",
        label: "Individual",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
  };

  static LEAD_MEMBER_EMAIL_TAGS = [
    {
      name: "First Name",
      tag: "{{first_name}}",
      description:
        'The first name of the lead/member. Default to "there" if not available.',
    },
    {
      name: "Last Name",
      tag: "{{last_name}}",
      description:
        "The last name of the lead/member. Default to empty if not available.",
    },
    {
      name: "Full Name",
      tag: "{{full_name}}",
      description:
        "The full name of the lead/member. Default to empty if not available.",
    },
    {
      name: "Email",
      tag: "{{email}}",
      description:
        "The email of the lead/member. Default to empty if not available.",
    },
    {
      name: "Phone",
      tag: "{{phone}}",
      description:
        "The phone of the lead/member. Default to empty if not available.",
    },
    {
      name: "Member ID",
      tag: "{{customer_id}}",
      description: "The member ID. Default to empty if not available.",
    },
    {
      name: "Lead ID",
      tag: "{{lead_id}}",
      description: "The lead ID. Default to empty if not available.",
    },
    {
      name: "Member Status",
      tag: "{{status}}",
      description:
        "The status of the member. Default to empty if not available.",
    },
    {
      name: "Member Referral Code",
      tag: "{{referral_code}}",
      description: "The referral code. Default to empty if not available.",
    },
    {
      name: "Referral URL",
      tag: "{{referral_url}}",
      description: "The referral URL. Default to empty if not available.",
    },
    {
      name: "App User ID",
      tag: "{{user_id}}",
      description:
        "The PLN app user ID (universal for members & leads). Default to empty if not available.",
    },
  ];

  static STORE_EMAIL_TAGS = [
    {
      name: "Store ID",
      tag: "{{store_id}}",
      description: "The ID of the store. Default to empty if not available.",
    },
    {
      name: "Store Name",
      tag: "{{store_name}}",
      description: "The name of the store. Default to empty if not available.",
    },
    {
      name: "Store Phone",
      tag: "{{store_phone}}",
      description:
        "The phone number of the store. Default to empty if not available.",
    },
    {
      name: "Store Email",
      tag: "{{store_email}}",
      description: "The email of the store. Default to empty if not available.",
    },
    {
      name: "Store Address",
      tag: "{{store_address}}",
      description:
        "The address of the store. Default to empty if not available.",
    },
    {
      name: "Store Website URL",
      tag: "{{store_website_url}}",
      description:
        "The website url of the store. Default to empty if not available.",
    },
    {
      name: "Store Staff Persona",
      tag: "{{store_staff_persona}}",
      description:
        'The persona of the store staff. Default to "the team" if not available.',
    },
    {
      name: "Store First Bill Date Readable",
      tag: "{{store_first_bill_date_readable}}",
      description:
        "The readable format of the first billing date of the store. Default to empty if not available.",
    },
    {
      name: "Store First Bill Month Year Readable",
      tag: "{{store_first_bill_month_year_readable}}",
      description:
        "The readable format of the first billing month and year of the store. Default to empty if not available.",
    },
    {
      name: "Store Prelaunch Date Readable",
      tag: "{{store_prelaunch_date_readable}}",
      description:
        "The readable format of the prelaunch date of the store. Default to empty if not available.",
    },
    {
      name: "Prelaunch Month Year Readable",
      tag: "{{prelaunch_month_year_readable}}",
      description:
        "The readable format of the prelaunch month and year. Default to empty if not available.",
    },
    {
      name: "Store Consult URL",
      tag: "{{store_consult_url}}",
      description:
        "The consultation URL of the store. Default to empty if not available.",
    },
  ];

  static SELECT_PICKER = {
    LEAD_STATUSES: [
      {
        label: "Not Contacted",
        value: "Not Contacted",
      },
      {
        label: "Attempted",
        value: "Attempted",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Converted",
        value: "closedwon",
      },
    ],
    LEAD_SOURCES: [
      {
        label: "Store Visit",
        value: "Store Visit",
        type: "In-Store",
      },
      {
        label: "Free InBody Scan",
        value: "Free InBody Scan",
        type: "In-Store",
      },
      {
        label: "Nutritional Consultation",
        value: "Nutritional Consultation",
        type: "In-Store",
      },
      {
        label: "Website: Visit",
        value: "Website: Visit",
        type: "Online",
      },

      {
        label: "Website: InBody",
        value: "Website: InBody",
        type: "Online",
      },
      {
        label: "Website: Macro Calculator",
        value: "Website: Macro Calculator",
        type: "Online",
      },
      {
        label: "Website: SMS Popup",
        value: "Website: SMS Popup",
        type: "Online",
      },
      {
        label: "Website: Lead Funnel",
        value: "Website: Lead Funnel",
        type: "Online",
      },
      {
        label: "Social Media",
        value: "Social Media",
        type: "Online",
      },
      {
        label: "Outreach",
        value: "Outreach",
        type: "Other",
      },
      {
        label: "Affiliate",
        value: "Affiliate",
        type: "Other",
      },
      {
        label: "Member Referral",
        value: "Client Referral",
        type: "Other",
      },
      {
        label: "SMS Conversation",
        value: "SMS Conversation",
        type: "Online",
      },
      {
        label: "Pop-Up Outeach Event",
        value: "Pop-Up Outeach Event",
        type: "Community Event",
      },
      {
        label: "Community Group Sweat Event",
        value: "Community Group Sweat Event",
        type: "Community Event",
      },
      {
        label: "Community Partner In-Store InReach",
        value: "Community Partner In-Store InReach",
        type: "Community Event",
      },
      {
        label: "Community Partner Drop-In",
        value: "Community Partner Drop-In",
        type: "Community Event",
      },
    ],
    CLIENT_GOALS: [
      {
        value: "Manage My Weight",
        label: "Manage My Weight",
      },
      {
        value: "Save Time",
        label: "Save Time",
      },
      {
        value: "Improve Health & Performance",
        label: "Improve Health & Performance",
      },
      {
        value: "Control My Portion Sizes",
        label: "Control My Portion Sizes",
      },
      {
        value: "Eat More Variety",
        label: "Eat More Variety",
      },
      {
        value: "Other Goals",
        label: "Other Goals",
      },
    ],
    FRANDEV_LEAD_STAGES: [
      { label: "Not Contacted" },
      { label: "Contacted" },
      { label: "Pre-Focus Call Nurturing" },
      { label: "Focus Call" },
      { label: "Discovery Call" },
      { label: "Pre-Discovery Day Nurturing" },
      { label: "Discovery Day" },
      { label: "Funding" },
      { label: "Converted" },
      { label: "Inactive" },
    ],
    FRANDEV_LEAD_SOURCES: [
      { label: "Referral" },
      { label: "Website" },
      { label: "LinkedIn: Organic" },
      { label: "FB/IG: Organic" },
      { label: "LinkedIn: Paid" },
      { label: "FB/IG: Paid" },
      { label: "Google: Paid" },
      {
        label: "Email",
      },
      {
        label: "Phone/SMS",
      },
      { label: "Lead List" },
      { label: "FDD List" },
      { label: "Paid Lead" },
      { label: "FranchiseHelp" },
      { label: "FranchiseClique.com" },
      { label: "NetCap" },
      { label: "Other" },
    ],
  };

  static TWILIO_MESSAGE_COST = 0.0079;

  static BROADCAST_LISTS = [
    {
      value: "dynamic:all_actives",
      label: "All Active Members",
      group: "Members",
    },
    {
      value: `dynamic:all_actives_this_week`,
      label: `Active This Week`,
      group: "Members",
    },
    {
      value: `dynamic:delivery_actives_this_week`,
      label: `Active This Week - Delivery List`,
      group: "Members",
    },
    {
      value: `dynamic:pickup_actives_this_week`,
      label: `Active This Week - Pickup List`,
      group: "Members",
    },
    {
      value: "dynamic:all_hfns",
      label: "All HFNs",
      group: "HFNs",
    },
    {
      value: "dynamic:all_leads",
      label: "All Leads",
      group: "Leads",
    },
  ];

  static SYSTEM_NOTIFICATION_LISTS = [
    {
      value: "development-team",
      label: "Development Team",
    },
    {
      value: "store-emails",
      label: "Store Admin Users",
    },
    {
      value: "franchise-partners",
      label: "Franchise Partners",
    },
    {
      value: "store-staff",
      label: "Store Staff",
    },
  ];

  static CUSTOMER_HOLD_REASONS = [
    {
      value: "Reached Goal",
      label: "Reached Goal",
    },
    {
      value: "Member Service Issue",
      label: "Member Service Issue",
    },
    {
      value: "Product Issue",
      label: "Product Issue",
    },
    {
      value: "Delivery Issue",
      label: "Delivery Issue",
    },
    {
      value: "Financial Issues",
      label: "Financial Issues",
    },
    {
      value: "Extended Travel",
      label: "Extended Travel",
    },
    {
      value: "Relocating",
      label: "Relocating",
    },
    {
      value: "Holiday/Seasonal",
      label: "Holiday/Seasonal",
    },
    {
      value: "Excessive Meals",
      label: "Excessive Meals",
    },
    {
      value: "Dietary Restrictions",
      label: "Dietary Restrictions",
    },
    {
      value: "Meal Prepping / Cooking For Themselves",
      label: "Meal Prepping / Cooking For Themselves",
    },
    {
      value: "Health/Medical Concern",
      label: "Health/Medical Concern",
    },
    {
      value: "Routine Order Cancellations",
      label: "Routine Order Cancellations",
    },
    {
      value: "Accidental Reactivation",
      label: "Accidental Reactivation",
    },
    {
      value: "Unpaid/Unclaimed",
      label: "Unpaid/Unclaimed",
    },
    {
      value: "Duplicate Account",
      label: "Duplicate Account",
    },
    {
      value: "Other",
      label: "Other",
    },
    {
      value: "No Information Available",
      label: "No Information Available",
    },
  ];

  static APPOINTMENT_TYPES = {
    "ec-consult": {
      APPOINTMENT_TYPE: "ec-consult",
      SHORT_NAME: "Member Check-In",
      NAME: "Member Check-In & Inbody Scan",
      DESCRIPTION:
        "Meet with your coach to take an InBody scan, assess progress towards your goals, and review your plan.",
      DURATION_SECONDS: 20 * 60,
      DURATION_READABLE: "20 min",
      LISTED: false,
      EXTRA_FIELDS: ["notes"],
    },
    "challenge-io": {
      APPOINTMENT_TYPE: "challenge-io",
      NAME: "6 Week Challenge: Weigh-In / Weigh-Out",
      SHORT_NAME: "Challenge Weigh-In/Out",
      DESCRIPTION:
        "Stop in for your 6 Week challenge weigh-in / weigh-out to track your progress and see how you've improved.",
      DURATION_SECONDS: 60 * 5,
      DURATION_READABLE: "5 min",
      LISTED: true,
      FIELDS: ["name", "email", "phone", "notes"],
    },
    "nc-consult": {
      APPOINTMENT_TYPE: "nc-consult",
      SHORT_NAME: "LEAN Consultation",
      NAME: "Client Consultation & Inbody Scan",
      DESCRIPTION:
        "Meet one-on-one with a coach to get a FREE InBody scan, discuss your goals, and develop a strategy to achieve success.",
      DURATION_SECONDS: 20 * 60,
      DURATION_READABLE: "20 min",
      LISTED: true,
      EXTRA_FIELDS: ["notes", "goal"],
    },
    "or-popup": {
      APPOINTMENT_TYPE: "or-popup",
      SHORT_NAME: "Pop-Up Event",
      NAME: "Pop-Up Outeach Event",
      DESCRIPTION: "",
      LISTED: false,
      EXTRA_FIELDS: ["notes"],
    },
    "or-groupsweat": {
      APPOINTMENT_TYPE: "or-groupsweat",
      SHORT_NAME: "Group Sweat",
      NAME: "Community Group Sweat Event",
      DESCRIPTION: "",
      LISTED: false,
      EXTRA_FIELDS: ["notes"],
    },
    "or-dropin": {
      APPOINTMENT_TYPE: "or-dropin",
      SHORT_NAME: "Drop-In",
      NAME: "Community Partner Drop-In",
      DESCRIPTION: "",
      LISTED: false,
      EXTRA_FIELDS: ["notes"],
    },
    "or-inreach": {
      APPOINTMENT_TYPE: "or-inreach",
      SHORT_NAME: "InReach",
      NAME: "Community Partner In-Store InReach",
      DESCRIPTION: "",
      LISTED: false,
      EXTRA_FIELDS: ["notes"],
    },
  };

  static FULFILLMENT_STRATEGIES = {
    IN_STORE: "In-Store Fulfillment",
    LLM_BOXED: "LeanLife Fulfillment",
  };

  static MEAL_PROTEINS = [
    {
      label: "Beef - Ground",
      value: "Beef - Ground",
      category: "Beef",
    },
    {
      label: "Beef - Shredded",
      value: "Beef - Shredded",
      category: "Beef",
    },
    {
      label: "Beef - Braised",
      value: "Beef - Braised",
      category: "Beef",
    },
    {
      label: "Beef - Shaved",
      value: "Beef - Shaved",
      category: "Beef",
    },
    {
      label: "Pork - Pulled",
      value: "Pork - Pulled",
      category: "Pork",
    },
    {
      label: "Pork - Ground",
      value: "Pork - Ground",
      category: "Pork",
    },
    {
      label: "Pork - Chorizo",
      value: "Pork - Chorizo",
      category: "Pork",
    },
    {
      label: "Pork - Pepperoni",
      value: "Pork - Pepperoni",
      category: "Pork",
    },
    {
      label: "Chicken - Diced",
      value: "Chicken - Diced",
      category: "Chicken",
    },
    {
      label: "Chicken - Strips",
      value: "Chicken - Strips",
      category: "Chicken",
    },
    {
      label: "Turkey - Sausage",
      value: "Turkey - Sausage",
      category: "Turkey",
    },
    {
      label: "Chicken - Pulled Thigh",
      value: "Chicken - Pulled Thigh",
      category: "Chicken",
    },
    {
      label: "Chicken - Roasted Thigh",
      value: "Chicken - Roasted Thigh",
      category: "Chicken",
    },
    {
      label: "Chicken - Breaded Chunks",
      value: "Chicken - Breaded Chunks",
      category: "Chicken",
    },
    {
      label: "Chicken - Braised Thigh",
      value: "Chicken - Braised Thigh",
      category: "Chicken",
    },
    {
      label: "Chicken - Breaded Strips",
      value: "Chicken - Breaded Strips",
      category: "Chicken",
    },
    {
      label: "Egg",
      value: "Egg",
      category: "Egg",
    },
    {
      label: "Chicken - Breaded",
      value: "Chicken - Breaded",
      category: "Chicken",
    },
    {
      label: "Turkey - Barbacoa",
      value: "Turkey - Barbacoa",
      category: "Turkey",
    },
    {
      label: "Turkey - Ground",
      value: "Turkey - Ground",
      category: "Turkey",
    },
    {
      label: "Beef - Burger",
      value: "Beef - Burger",
      category: "Beef",
    },
    {
      label: "Plant Based",
      value: "Plant Based",
      category: "Plant Based",
    },
    {
      label: "Fish - Salmon",
      value: "Fish - Salmon",
      category: "Fish",
    },
    {
      label: "Fish - Shrimp",
      value: "Fish - Shrimp",
      category: "Fish",
    },
    {
      label: "Fish - Tilapia",
      value: "Fish - Tilapia",
      category: "Fish",
    },
    {
      label: "Chicken - Grilled Chunk",
      value: "Chicken - Grilled Chunk",
      category: "Chicken",
    },
    {
      label: "Chicken - Grilled Strip",
      value: "Chicken - Grilled Strip",
      category: "Chicken",
    },
    {
      label: "Chicken - Breast",
      value: "Chicken - Breast",
      category: "Chicken",
    },
    {
      label: "Beef - Barbacoa",
      value: "Beef - Barbacoa",
      category: "Beef",
    },
    {
      label: "Pork - Sausage",
      value: "Pork - Sausage",
      category: "Pork",
    },
    {
      label: "Pork - Bacon",
      value: "Pork - Bacon",
      category: "Pork",
    },
    {
      label: "Pork - Braised, Cubed",
      value: "Pork - Braised, Cubed",
      category: "Pork",
    },
    {
      label: "Chicken - Sausage",
      value: "Chicken - Sausage",
      category: "Chicken",
    },
    {
      label: "Lean Cheat",
      value: "Lean Cheat",
      category: "Lean Cheat",
    },
  ];

  static BIRD_ERROR_CODES = {
    0: {
      NAME: "EC_NO_ERROR",
      DESCRIPTION: "No errors.",
      FULL_DESCRIPTION: "No errors encountered.",
      TYPE: "",
    },
    1: {
      NAME: "EC_UNKNOWN_SUBSCRIBER",
      DESCRIPTION: "The phone number is not active.",
      FULL_DESCRIPTION:
        "The recipient's number is not linked to an active account.",
      TYPE: "Permanent",
    },
    2: {
      NAME: "EC_UNKNOWN_BASE_STATION",
      DESCRIPTION: "The receipient's phone is connected to an unknown device.",
      FULL_DESCRIPTION:
        "The recipient's number is connected to an unknown device on the mobile carrier network.",
      TYPE: "Permanent",
    },
    3: {
      NAME: "EC_UNKOWN_MSC",
      DESCRIPTION:
        "The receipient's phone is connected to an unrecognized mobile carrier network.",
      FULL_DESCRIPTION:
        "The recipient's number is currently connected to an unrecognized mobile carrier network device.",
      TYPE: "Intermediate",
    },
    5: {
      NAME: "EC_UNIDENTIFIED_SUBSCRIBER",
      DESCRIPTION: "The receipient's phone number is not active.",
      FULL_DESCRIPTION:
        "The recipient's number is not linked to an active account.",
      TYPE: "Intermediate",
    },
    7: {
      NAME: "EC_UNKNOWN_EQUIPMENT",
      DESCRIPTION:
        "The receipient's phone can't connect to the mobile carrier network.",
      FULL_DESCRIPTION:
        "The recipient's number is valid, but their device can't connect to the mobile mobile carrier network.",
      TYPE: "Intermediate",
    },
    8: {
      NAME: "EC_ROAMING_NOT_ALLOWED",
      DESCRIPTION:
        "Roaming is not allowed in the receipient's current location.",
      FULL_DESCRIPTION:
        "The recipient is outside their home mobile carrier network and isn't authorized to roam. The issue will resolve once they return to their network.",
      TYPE: "Intermediate",
    },
    9: {
      NAME: "EC_ILLEGAL_SUBSCRIBER",
      DESCRIPTION:
        "The receipient's phone failed to connect to the mobile carrier network.",
      FULL_DESCRIPTION:
        "The recipient failed to authenticate with the mobile mobile carrier network.",
      TYPE: "Permanent",
    },
    10: {
      NAME: "EC_BEARERSERVICE_NOT_PROVISIONED",
      DESCRIPTION: "SMS service is not available for the receipient's number.",
      FULL_DESCRIPTION:
        "The recipient's number isn't set up to receive SMS, possibly due to dual numbering, where the other number is SMS-enabled.",
      TYPE: "Intermediate",
    },
    11: {
      NAME: "EC_TELESERVICE_NOT_PROVISIONED",
      DESCRIPTION:
        "SMS service is not available for the receipient's type of number.",
      FULL_DESCRIPTION:
        "The recipient's number isn't set up to receive SMS, possibly because it's an IoT number or the subscriber's plan doesn't support SMS.",
      TYPE: "Intermediate",
    },
    12: {
      NAME: "EC_ILLEGAL_EQUIPMENT",
      DESCRIPTION: "The device is blacklisted.",
      FULL_DESCRIPTION:
        "The recipient's number is valid, but their device is blacklisted as stolen or lost.",
      TYPE: "Intermediate",
    },
    13: {
      NAME: "EC_CALL_BARRED",
      DESCRIPTION: "SMS services are blocked for the receipient's number.",
      FULL_DESCRIPTION:
        "The recipient's SMS services are blocked, usually due to an unpaid bill or lack of credit.",
      TYPE: "Intermediate",
    },
    21: {
      NAME: "EC_FACILITY_NOT_SUPPORTED",
      DESCRIPTION:
        "The receipient's mobile carrier network does not support SMS.",
      FULL_DESCRIPTION:
        "The SMS couldn't be delivered because the recipient's mobile carrier network doesn't support SMS.",
      TYPE: "Intermediate",
    },
    26: {
      NAME: "EC_SUBSEQUENT_HANDOVER_FAILURE",
      DESCRIPTION:
        "The receipient's phone is moving between mobile carrier network areas.",
      FULL_DESCRIPTION:
        "The recipient is moving between mobile carrier network areas, and the SMS couldn't be delivered.",
      TYPE: "Intermediate",
    },
    27: {
      NAME: "EC_ABSENT_SUBSCRIBER",
      DESCRIPTION:
        "The receipient's phone could not be found on the mobile carrier network.",
      FULL_DESCRIPTION:
        "The recipient couldn't be found on their mobile carrier network.",
      TYPE: "Intermediate",
    },
    28: {
      NAME: "EC_ABSENT_SUBSCRIBER_NO_PAGE",
      DESCRIPTION: "The receipient's phone is out of coverage.",
      FULL_DESCRIPTION: "The recipient is out of coverage.",
      TYPE: "Intermediate",
    },
    29: {
      NAME: "EC_ABSENT_SUBSCRIBER_IMSI_DETACHED",
      DESCRIPTION: "The receipient's phone is switched off.",
      FULL_DESCRIPTION: "The recipient's device is switched off.",
      TYPE: "Intermediate",
    },
    30: {
      NAME: "EC_CONTROLLING_MSC_FAILURE",
      DESCRIPTION:
        "There is a temporary mobile carrier network issue for the receipient.",
      FULL_DESCRIPTION:
        "The mobile carrier network device the recipient is connected to is experiencing a temporary failure and/or planned maintenance.",
      TYPE: "Intermediate",
    },
    31: {
      NAME: "EC_SUBSCRIBER_BUSY_FOR_MT_SMS",
      DESCRIPTION: "The receipient's phone is busy and can't receive the SMS.",
      FULL_DESCRIPTION:
        "The recipient is currently busy (example performing USSD) and the SMS could not be delivered.",
      TYPE: "Intermediate",
    },
    32: {
      NAME: "EC_SM_DELIVERY_FAILURE",
      DESCRIPTION: "SMS delivery failed.",
      FULL_DESCRIPTION: "",
      TYPE: "Intermediate",
    },
    33: {
      NAME: "EC_MESSAGE_WAITING_LIST_FULL",
      DESCRIPTION: "The recipient's inbox is full.",
      FULL_DESCRIPTION:
        "The recipient has received more SMS than the local mobile carrier network nodes can store for a single user.",
      TYPE: "Intermediate",
    },
    34: {
      NAME: "EC_SYSTEM_FAILURE",
      DESCRIPTION:
        "There is a problem with the receipient's mobile carrier network.",
      FULL_DESCRIPTION:
        "A generic issue with the recipient's mobile carrier  network.",
      TYPE: "Intermediate",
    },
    35: {
      NAME: "EC_DATA_MISSING",
      DESCRIPTION: "Required information is missing.",
      FULL_DESCRIPTION:
        "A mandatory (by the recipient's mobile carrier network) field was empty.",
      TYPE: "Intermediate",
    },
    36: {
      NAME: "EC_UNEXPECTED_DATA_VALUE",
      DESCRIPTION: "There was a data error.",
      FULL_DESCRIPTION:
        "The value in one of the SMS fields was incorrect (e.g., the charset used for encoding the SMS).",
      TYPE: "Intermediate",
    },
    39: {
      NAME: "EC_NO_ROAMING_NUMBER_AVAILABLE",
      DESCRIPTION: "No roaming number was available.",
      FULL_DESCRIPTION:
        "The recipient is roaming, and a temporary redirecting number was not available to reroute the SMS to the correct destination.",
      TYPE: "Intermediate",
    },
    40: {
      NAME: "EC_MEMORY_CAPACITY_EXCEEDED",
      DESCRIPTION:
        "The receipient's mobile carrier network has run out of memory for SMS.",
      FULL_DESCRIPTION:
        "An unspecified equipment on the recipient's mobile carrier  network has no memory left to handle incoming SMS traffic.",
      TYPE: "Intermediate",
    },
    71: {
      NAME: "EC_UNKNOWN_ALPHABET",
      DESCRIPTION: "The SMS is in an unsupported language.",
      FULL_DESCRIPTION:
        "The SMS is written in an alphabet or charset that is not supported by the recipient's mobile carrier  network.",
      TYPE: "Permanent",
    },
    72: {
      NAME: "EC_USSD_BUSY",
      DESCRIPTION: "The receipient's phone is busy with another service.",
      FULL_DESCRIPTION: "",
      TYPE: "Intermediate",
    },
    101: {
      NAME: "EC_SUBSCRIBER_INSUFFICIENT_BALANCE",
      DESCRIPTION: "The recipient doesn't have enough balance.",
      FULL_DESCRIPTION: "",
      TYPE: "Intermediate",
    },
    103: {
      NAME: "EC_SUBSCRIBER_OPTEDOUT",
      DESCRIPTION: "The recipient has opted out of receiving SMS.",
      FULL_DESCRIPTION:
        "The recipient opted out from receiving SMS; all future SMS will be blocked. This can only be resolved by the recipient sending an opt-in keyword like 'START'.",
      TYPE: "Permanent",
    },
    104: {
      NAME: "EC_SENDER_UNREGISTERED",
      DESCRIPTION:
        "The sender is not allowed by the recipient's mobile carrier network.",
      FULL_DESCRIPTION:
        "The originator used in sending the SMS is not allowed by the recipient's mobile operator, usually due to a lack of registration.",
      TYPE: "Permanent",
    },
    105: {
      NAME: "EC_CONTENT_UNREGISTERED",
      DESCRIPTION:
        "The SMS content is not allowed by the recipient's mobile carrier network.",
      FULL_DESCRIPTION:
        "The content of the SMS is not allowed by the recipient's mobile operator, usually due to a lack of registration.",
      TYPE: "Permanent",
    },
    106: {
      NAME: "EC_CAMPAIGN_VOLUME_EXCEEDED",
      DESCRIPTION: "The sender's campaign has reached its SMS volume limit.",
      FULL_DESCRIPTION:
        "The volume cap associated with the campaign of the SMS (via used originator) is reached at the receiving operator, and further SMS will be blocked until the volume cap resets.",
      TYPE: "Intermediate",
    },
    107: {
      NAME: "EC_CAMPAIGN_THROUGHPUT_EXCEEDED",
      DESCRIPTION: "The sender's campaign has reached its SMS rate limit.",
      FULL_DESCRIPTION:
        "The maximum SMS rate associated with the campaign of the SMS (via used originator) is reached at the receiving operator, and excess SMS are being blocked.",
      TYPE: "Intermediate",
    },
    110: {
      NAME: "EC_MESSAGE_FILTERED",
      DESCRIPTION:
        "The SMS was blocked/filtered by the recipient's mobile carrier network.",
      FULL_DESCRIPTION:
        "The SMS originator or content is not allowed by the recipient's mobile operator. Please consult the applicable regulations and forbidden content or originators.",
      TYPE: "Permanent",
    },
  };
}

export default Constant;
